import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PostItem from './PostItem';
import { getPostsFollowing } from '../../actions/follow';
import { FloatButton } from 'antd';
import { Pagination, Input } from 'antd';
import CreatePostModal from './CreatePostModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import './Posts.css';

const { Search } = Input;

const Posts = ({ getPostsFollowing, post: { posts, totalPosts } }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  let searchQuery = query.get('search') || '';
  let pageQuery = query.get('page') || 1;
  let pageSizeQuery = query.get('pageSize') || 20;
  const [isPostsLoaded, setIsPostsLoaded] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  useEffect(() => {
    const fetchPosts = async () => {
      await getPostsFollowing(pageQuery, pageSizeQuery, searchQuery);
      setIsPostsLoaded(true);
    };
    fetchPosts();
  }, [getPostsFollowing, pageQuery, pageSizeQuery, searchQuery]);

  const handlePageChange = async (page, pageSize) => {
    if (searchQuery === null) {
      searchQuery = '';
    }
    navigate(`/posts-following?page=${page}&pageSize=${pageSize}&search=${searchQuery}`);
  };

  const onSearch = async (value, _e, info) => {
    if (value === '') {
      navigate(`/posts-following?page=${1}&pageSize=${12}`);
    }
    navigate(`/posts-following?search=${value}`);
  };

  return (
    <section className="container">
      <div className="posts-list-header-container">
        <div className="posts-list-header-search-input">
          <Search
            className="computer-layout"
            placeholder="search posts"
            allowClear
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>

      <Search
        className="mobile-layout"
        style={{ marginBottom: '0.5rem' }}
        placeholder="search posts"
        allowClear
        size="large"
        onSearch={onSearch}
      />
      {isPostsLoaded && posts && posts.length > 0 && (
        <>
          <div className="computer-layout">
            <div className={`posts`}>
              <div className="items-container">
                {posts
                  .filter((_, index) => index % 4 === 0)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {posts
                  .filter((_, index) => index % 4 === 1)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {posts
                  .filter((_, index) => index % 4 === 2)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {posts
                  .filter((_, index) => index % 4 === 3)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
            </div>
          </div>

          <div className="mobile-layout">
            <div className="posts">
              <div className="items-container">
                {posts
                  .filter((_, index) => index % 2 !== 0)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {posts
                  .filter((_, index) => index % 2 === 0)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="m-2">
        <Pagination
          showSizeChanger
          current={pageQuery && Number(pageQuery)}
          defaultCurrent={1}
          defaultPageSize={20}
          pageSizeOptions={[3, 6, 12, 20, 36]}
          onChange={handlePageChange}
          total={totalPosts}
        />
      </div>
      <FloatButton.Group shape="circle" style={{ right: 48 }}>
        <FloatButton
          type="primary"
          className="float-button-add-post"
          icon={<FontAwesomeIcon icon={faPlus} />}
          styles={{ defaultBg: '#1890ff' }}
          badge={{
            dot: true,
          }}
          onClick={() => {
            setModalVisible(true);
          }}
        ></FloatButton>
        <FloatButton.BackTop
          className="float-button-add-post"
          style={{ zoom: 1.3 }}
          visibilityHeight={0}
        />
      </FloatButton.Group>
      <CreatePostModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </section>
  );
};

Posts.propTypes = {
  getPostsFollowing: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPostsFollowing })(Posts);
