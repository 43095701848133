import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import icon from '../../img/CuteIcon.png';
import googleIcon from '../../img/google_g_icon.png';

import styles from './auth.module.css';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;
  const [showPassword, setShowPassword] = useState(false);
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const togglePasswordVisiblity = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <section className="container">
      <section className={styles.authContainer}>
        <img className="profiles-icon-medium " src={icon} alt=""></img>
        <h1 className={styles.largeText}>Login</h1>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.formGroup}>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              className={styles.inputField}
              value={email}
              onChange={onChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              name="password"
              className={styles.inputField}
              value={password}
              onChange={onChange}
              minLength="6"
              required
            />
            <i
              onClick={togglePasswordVisiblity}
              className={`${styles.passwordIcon} ${
                showPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'
              }`}
            ></i>
          </div>
          <input type="submit" className={styles.loginButton} value="LOGIN" />
        </form>
        <p className={styles.bottomText}>
          <Link to="/reset-password-email-submission" className={styles.forgetPasswordLink}>
            Forgot Your Password?
          </Link>
        </p>
        {/* <p className={styles.bottomText}>
          Not registered?{' '}
          <Link to="/register" className={styles.signUpLink}>
            SIGN UP
          </Link>
        </p> */}
        <p className={styles.newAccountText}>
          If you don't have an account, create one now to get started.
        </p>{' '}
        {/* New line added */}
        <Link to="/email-submission" className={styles.registerButton}>
          Create Account
        </Link>{' '}
        <div className={styles.divider}>
          <div className={styles.dividerLine} />
          <span className={styles.dividerText}>OR</span>
          <div className={styles.dividerLine} />
        </div>
        <div className={styles.socialLogin}>
          <a
            href={`${process.env.REACT_APP_BACKEND_APIENDPOINT}/users/auth/google`}
            className={styles.googleLoginButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googleIcon} alt="Google" className={styles.googleIcon} />
            <span className={styles.googleText}>Login with Google</span>
          </a>
        </div>
        {/* New button added */}
      </section>
    </section>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
