import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import calculateAge from '../../utils/calculateAge';
// import useFadeInTopToBottom from '../animation/fadeIn/useFadeInTopToBottom';
import useSlideInAnimation from '../animation/scrollDownSlideIn/useSlideInAnimation';

import '../animation/scrollDownSlideIn/scrollDownSlideIn.css';

const ProfileItem = ({ id, cat: { _id, user, avatar, name, status, birthday, breed, sex } }) => {
  const navigate = useNavigate();
  const { ref, isVisible, isAlreadyInView } = useSlideInAnimation();
  // This is the function that will be called when a comment notification is clicked
  const handleOnProfile = (userId, catId) => {
    // Navigate to the post page
    // Wait for the navigation and page load to complete
    setTimeout(() => {
      // Now that we're on the post page, find the comment element
      const commentElement = document.getElementById(`cat-${catId}`);
      // If the comment element exists, scroll to it
      commentElement?.scrollIntoView({ behavior: 'smooth' });
    }, 1000); // You might need to adjust the timeout based on your app's behavior
  };
  return (
    <div
      id={id}
      ref={ref}
      className={`profile bg-light 
      ${isAlreadyInView ? '' : 'initial-position'}
        ${isVisible ? 'slide-in-up' : ''}`}
    >
      <div
        className="avatar-container"
        onClick={() => {
          navigate(`/profile/${user}`);
          handleOnProfile(user, _id);
        }}
      >
        <div className="avatar">
          {avatar ? (
            <img src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + avatar} alt={name} />
          ) : (
            <img
              src={
                process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT +
                'public/user/avatar/cat-default-avatar.png'
              }
              alt={name}
            />
          )}
        </div>
      </div>
      <div>
        <h2>{name}</h2>

        <p className="ml" style={{ color: 'gray' }}>
          {sex}
        </p>
        <p className="ml" style={{ color: 'gray' }}>
          {calculateAge(birthday)}
        </p>
        <p className="ml" style={{ color: 'gray' }}>
          {breed}
        </p>
        <Link
          to={`/profile/${user}`}
          onClick={() => {
            handleOnProfile(user, _id);
          }}
          className="btn btn-primary"
        >
          View Profile
        </Link>
      </div>
    </div>
  );
};

ProfileItem.propTypes = {
  cat: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default ProfileItem;
