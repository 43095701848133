import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_LIKES,
  DELETE_POST,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  COMMENT_ERROR,
  GET_COMMENTS,
  UPDATE_COMMENT_LIKES,
  RESET_POSTS,
  VISIBLE_POST,
  GET_CONTINUED_POSTS,
  GET_SUBCOMMENT,
} from '../actions/types';

const initialState = {
  posts: [],
  continuePosts: [],
  currentPages: 0,
  title: '',
  post: null,
  loading: true,
  loadedAll: false,
  error: {},
};

function postReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POSTS:
      return {
        ...state,
        posts: payload.resPosts,
        totalPosts: payload.totalPosts,
        loading: false,
      };
    case GET_CONTINUED_POSTS: {
      const { data, pageNum, title } = payload;
      const { resPosts, totalPosts } = data;
      const isTitleChanged = title !== state.title;
      if (isTitleChanged) {
        return {
          ...state,
          posts: resPosts,
          continuePosts: resPosts,
          totalPosts: totalPosts,
          currentPages: 1,
          title: title,
          loadedAll: resPosts.length === totalPosts,
          loading: false,
        };
      } else {
        if (state.currentPages >= pageNum) {
          return {
            ...state,
            totalPosts: totalPosts,
            loading: false,
          };
        } else {
          const isLoadedAll = resPosts.length + state.continuePosts.length === totalPosts;
          return {
            ...state,
            posts: resPosts,
            continuePosts: [...state.continuePosts, ...resPosts],
            totalPosts: totalPosts,
            currentPages: pageNum,
            loadedAll: isLoadedAll,
            loading: false,
          };
        }
      }
    }
    case GET_POST:
      return {
        ...state,
        post: payload,
        loading: false,
      };
    case ADD_POST:
      return {
        ...state,
        posts: [payload, ...state.posts],
        continuePosts: [payload, ...state.continuePosts],
        loading: false,
      };
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== payload),
        continuePosts: state.continuePosts.filter((post) => post._id !== payload),
        loading: false,
      };
    case VISIBLE_POST: {
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === payload.postId ? { ...post, visible: payload.isVisible } : post,
        ),
        post:
          state.post && state.post._id === payload.postId
            ? { ...state.post, visible: payload.isVisible }
            : state.post,
        continuePosts: state.continuePosts.map((post) =>
          post._id === payload.postId ? { ...post, visible: payload.isVisible } : post,
        ),
        loading: false,
      };
    }
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPDATE_LIKES: {
      return {
        ...state,
        continuePosts: state.continuePosts.map((post) =>
          post._id === payload.id
            ? {
                ...post,
                isLiked: payload.isLiked,
                likesCount: post.likesCount + (payload.isLiked ? 1 : -1),
              }
            : post,
        ),
        posts: state.posts.map((post) =>
          post._id === payload.id
            ? {
                ...post,
                isLiked: payload.isLiked,
                likesCount: post.likesCount + (payload.isLiked ? 1 : -1),
              }
            : post,
        ),
        post: state.post
          ? {
              ...state.post,
              likesCount: (state.post.likesCount || 0) + (payload.isLiked ? 1 : -1),
              isLiked: payload.isLiked,
            }
          : state.post,
        loading: false,
      };
    }
    case UPDATE_COMMENT_LIKES: {
      const updatedComments = state.post.comments.slice();
      if (payload.rootId !== '') {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.rootId,
        );
        console.log('existingCommentIndex:', existingCommentIndex);
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          const subcommentIndex = updatedComment.subcomments.findIndex(
            (subcomment) => subcomment._id === payload.commentId,
          );
          console.log('subcommentIndex:', subcommentIndex);
          if (subcommentIndex !== -1) {
            const updatedSubcomment = { ...updatedComment.subcomments[subcommentIndex] };
            updatedSubcomment.likesCount += payload.isLiked ? 1 : -1;
            updatedSubcomment.isLiked = payload.isLiked;
            updatedComment.subcomments[subcommentIndex] = updatedSubcomment;
          }
          updatedComments[existingCommentIndex] = updatedComment;
        }
      } else {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.commentId,
        );

        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          updatedComment.likesCount += payload.isLiked ? 1 : -1;
          updatedComment.isLiked = payload.isLiked;
          updatedComments[existingCommentIndex] = updatedComment;
        }
      }
      return {
        ...state,
        post: {
          ...state.post,
          comments: updatedComments,
        },
        loading: false,
      };
    }
    case ADD_COMMENT: {
      const updatedComments = state.post.comments.slice();

      if (payload.rootId) {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.rootId,
        );
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          if (updatedComment.subcomments) {
            updatedComment.subcomments.push(payload);
            updatedComment.subcommentsNum = updatedComment.subcomments.length;
          } else {
            updatedComment.subcomments = [payload];
            updatedComment.subcommentsNum = 1;
          }
          updatedComments[existingCommentIndex] = updatedComment;
        }
      } else {
        updatedComments.unshift(payload);
      }
      return {
        ...state,
        post: {
          ...state.post,
          comments: updatedComments,
        },
        loading: false,
      };
    }
    case GET_COMMENTS:
      return {
        ...state,
        post: {
          ...state.post,
          comments: payload.resComments,
          totalComments: payload.totalComments,
        },
        loading: false,
      };
    case GET_SUBCOMMENT: {
      const updatedComments = state.post.comments.slice();
      const existingCommentIndex = updatedComments.findIndex(
        (comment) => comment._id === payload.commentId,
      );
      if (existingCommentIndex !== -1) {
        const updatedComment = { ...updatedComments[existingCommentIndex] };
        updatedComment.subcomments = payload.subcomments;
        updatedComments[existingCommentIndex] = updatedComment;
      }
      return {
        ...state,
        post: {
          ...state.post,
          comments: updatedComments,
        },
        loading: false,
      };
    }
    case COMMENT_ERROR:
      return {
        ...state,
        post: { ...state.post, comments: null },
        loading: false,
      };
    case REMOVE_COMMENT: {
      const updatedComments = state.post.comments.slice();
      let newComments;
      if (payload.data.rootId) {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.data.rootId,
        );
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          updatedComment.subcomments = updatedComment.subcomments.filter(
            (subcomment) => subcomment._id !== payload.commentId,
          );
          updatedComment.subcommentsNum = updatedComment.subcomments.length;
          updatedComments[existingCommentIndex] = updatedComment;
        }
        newComments = updatedComments;
      } else {
        newComments = updatedComments.filter((comment) => comment._id !== payload.commentId);
      }
      return {
        ...state,
        post: {
          ...state.post,
          comments: newComments,
        },
        loading: false,
      };
    }
    case RESET_POSTS: {
      return {
        ...state,
        posts: [],
        post: null,
        loading: true,
        error: {},
      };
    }
    default:
      return state;
  }
}

export default postReducer;
