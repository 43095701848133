// App.js
import React, { useState, useEffect } from 'react';
import styles from './BotWelcome.module.css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { requestAccess, activateCode } from '../../actions/chatbot';

function App({ auth: { isAuthenticated, user }, requestAccess, activateCode }) {
  const [activationCode, setActivationCode] = useState('');

  const [showAccessButtons, setShowAccessButtons] = useState(false);
  useEffect(() => {
    if (user?.grantedAccess?.vetbot) {
      setShowAccessButtons(false);
    }
  }, [user]);
  const handleClickTryChatGPT = () => {
    if (user?.grantedAccess?.vetbot) {
      // If the user already has access, navigate to '/chatbots'
      return;
    }
    setShowAccessButtons(true); // Triggers the animation
  };

  return (
    <div className={styles.app}>
      <header className={styles.appHeader}>
        <h1 className={styles.title}>Welcome to VetGPT</h1>
        <p className={styles.description}>
          VetGPT is your virtual veterinary assistant, ready to discuss pet health and wellness.
          Whether you have questions about your pet's diet, behavior, or symptoms, VetGPT is trained
          to provide guidance, suggest when to seek veterinary care, and offer support for everyday
          pet care.
        </p>
        <div className={styles.buttons}>
          <Link
            to={user?.grantedAccess?.vetbot ? '/chatbots' : ''}
            className={`${styles.button} ${styles.tryChatGPT} ${
              showAccessButtons ? styles.hideButton : ''
            }`}
            onClick={handleClickTryChatGPT}
          >
            Try VetGPT
          </Link>
          <div
            className={`${styles.accessButtons} ${
              showAccessButtons ? styles.showAccessButtons : ''
            }`}
          >
            <input
              type="text"
              className={styles.activationInput}
              placeholder="Enter your activation code"
              value={activationCode}
              onChange={(e) => setActivationCode(e.target.value)}
            />
            <button
              className={`${styles.button} ${styles.tryChatGPT}`}
              onClick={() => activateCode(activationCode)}
            >
              Activate
            </button>
            <button
              className={`${styles.button} ${styles.tryChatGPT}`}
              onClick={() => {
                requestAccess(isAuthenticated);
              }}
            >
              Request Access
            </button>
          </div>
        </div>
      </header>
    </div>
  );
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  requestAccess: PropTypes.func.isRequired,
  activateCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { requestAccess, activateCode })(App);
