import api from '../utils/api';
import { getMyFollowers, getMyFollowings } from './follow';
import { getNotificationByType } from './notification';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESET_POSTS,
} from './types';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get('/auth');

    await dispatch(getMyFollowers());
    await dispatch(getMyFollowings());
    await dispatch(getNotificationByType(1, 1, 'like'));
    await dispatch(getNotificationByType(1, 1, 'comment'));
    await dispatch(getNotificationByType(1, 1, 'follow'));
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/users', formData);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    const errors = err?.response?.data?.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post('/auth', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err?.response?.data?.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

export const sendEmailToRegister = (email) => async (dispatch) => {
  try {
    await api.post('/users/register', { email });
    dispatch(setAlert('Sent confirmation code', 'success'));
    return true;
  } catch (err) {
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err?.response?.data?.errors) {
      err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

export const verifyEmail = (email, emailToken) => async (dispatch) => {
  try {
    const res = await api.post('/users/verify-email', { email, emailToken });
    if (res.data.code === 401) {
      dispatch(setAlert('Invalid code', 'danger'));
      return false;
    }
    dispatch(setAlert(res.data.msg, 'success'));
    return true;
  } catch (err) {
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err?.response?.data?.errors) {
      err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

export const sendEmailToResetPassword = (email) => async (dispatch) => {
  try {
    const res = await api.post('/users/reset-password-email', { email });
    if (res.data.code === 404) {
      dispatch(setAlert('User not found', 'danger'));
      return false;
    }
    dispatch(setAlert('Sent reset code to email', 'success'));
    return true;
  } catch (err) {
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err?.response?.data?.errors) {
      err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

export const resetPassword = (email, emailToken, password) => async (dispatch) => {
  try {
    const res = await api.post('/users/reset-password', { email, password, emailToken });
    if (res.data.code === 404 || res.data.code === 401) {
      dispatch(setAlert(res.data.msg, 'danger'));
      return false;
    }
    dispatch(setAlert('Password reset successfully', 'success'));
    return true;
  } catch (err) {
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err?.response?.data?.errors) {
      err.response.data.errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// Logout
export const logout = () => async (dispatch) => {
  try {
    const res = await api.post('/auth/logout');
    window.OneSignalDeferred.push(function (OneSignal) {
      OneSignal.User.PushSubscription.optOut();
    });
    window.OneSignalDeferred.push(function (OneSignal) {
      OneSignal.logout();
    });
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (error) {
    console.error('Logout error:', error);
  }
  dispatch({ type: RESET_POSTS });
  dispatch({ type: LOGOUT });
};
