import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getIsFollowing,
  addFollowing,
  removeFollowing,
  getIsFriend,
} from '../../../actions/follow';
import { useThrottle } from '../../../utils/debounce';
import './FollowButton.css'; // Make sure to create a corresponding CSS file

const FollowButton = ({
  user,
  getIsFollowing,
  addFollowing,
  removeFollowing,
  getIsFriend,
  unfollowedText,
  followedText,
  onFollowingChange,
}) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  useEffect(() => {
    const fetchIsFollowing = async () => {
      const isFollowingUser = await getIsFollowing(user);
      const isFriendUser = await getIsFriend(user);
      // console.log(isFollowingUser);
      setIsFollowing(isFollowingUser);
      setIsFriend(isFriendUser);
    };
    fetchIsFollowing();
  }, [getIsFollowing, getIsFriend, user]);

  const handleFollowClick = useThrottle(() => {
    if (!isFollowing) {
      // Optimistically set following to true
      const fetchIsFriend = async () => {
        await addFollowing(user); // This function should perform the actual follow action, e.g., API call
        const isFriendUser = await getIsFriend(user);
        setIsFriend(isFriendUser);
        setIsFollowing(true);
        if (onFollowingChange) {
          onFollowingChange(true);
        }
      };
      fetchIsFriend();
    } else {
      removeFollowing(user); // This function should perform the actual unfollow action, e.g., API call
      setIsFollowing(false); // Optimistically set following to false
      if (onFollowingChange) {
        onFollowingChange(false);
      }
    }
  }, 1000); // Throttle for 5 seconds

  return (
    <button
      className={`follow-button ${isFollowing ? 'following' : ''}`}
      onClick={handleFollowClick}
    >
      {isFollowing ? (
        <>{isFriend ? 'Friend' : `${followedText ? ` ${followedText}` : 'Following'}`}</>
      ) : (
        `${unfollowedText ? ` ${unfollowedText}` : 'Follow'}`
      )}
    </button>
  );
};

FollowButton.propTypes = {
  getIsFollowing: PropTypes.func.isRequired,
  getIsFriend: PropTypes.func.isRequired,
  addFollowing: PropTypes.func.isRequired,
  removeFollowing: PropTypes.func.isRequired,
  onFollowingChange: PropTypes.func,
  user: PropTypes.string.isRequired,
  unfollowedText: PropTypes.string,
  followedText: PropTypes.string,
};

export default connect(null, { getIsFollowing, addFollowing, removeFollowing, getIsFriend })(
  FollowButton,
);
