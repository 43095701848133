import { useState, useRef, useEffect } from 'react';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL, fetchFile } from '@ffmpeg/util';

const useFFmpegTranscoder = () => {
  const [isFFmpegLoaded, setFFmpegLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg({ log: true }));
  const [messageLoading, setMessage] = useState(null);
  useEffect(() => {
    loadFFmpeg();
  }, []);

  const loadFFmpeg = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.4/dist/umd';
    const ffmpeg = ffmpegRef.current;

    ffmpeg.on('progress', ({ progress }) => {
      // You can use a callback or an event emitter to report progress outside the hook
      setMessage(`${progress * 100}`);
    });

    // const toBlobURL = async (url, mimeType) => {
    //   const response = await fetch(url);
    //   const blob = await response.blob();
    //   return URL.createObjectURL(new Blob([blob], { type: mimeType }));
    // };

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });
    setFFmpegLoaded(true);
  };

  const transcodeVideo = async (file) => {
    if (!isFFmpegLoaded) {
      console.error('FFmpeg is not loaded yet.');
      return;
    }

    await ffmpegRef.current.writeFile('input', await fetchFile(file));
    await ffmpegRef.current.exec([
      '-i',
      'input',
      '-f',
      'mp4',
      '-movflags',
      '+faststart',
      '-vf',
      'scale=iw*0.7:ih*0.7', // Scale filter to reduce resolution to 70%
      '-pix_fmt',
      'yuv420p',
      '-c:v',
      'libx264', // Specify H.264 codec
      '-c:a',
      'aac', // Specify AAC codec
      '-preset',
      'ultrafast',
      'output.mp4',
    ]);
    const data = await ffmpegRef.current.readFile('output.mp4');
    return new File([data.buffer], 'output.mp4', { type: 'video/mp4' });
  };

  return { transcodeVideo, isFFmpegLoaded, messageLoading };
};

export default useFFmpegTranscoder;
