import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import NotificationItem from './NotificationItem';
import {
  getUnreadNotification,
  getReadNotification,
  getNotificationByType,
  ReadAllNotification,
} from '../../actions/notification';
import { Pagination } from 'antd';

import './Notifications.css';

// const { Search } = Input;

const Notifications = ({
  getUnreadNotification,
  getReadNotification,
  getNotificationByType,
  ReadAllNotification,
  notification: {
    totalUnreadLikeNotifications,
    totalUnreadCommentNotifications,
    totalUnreadFollowNotifications,
    notifications,
    unreads,
    reads,
    loading,
    totalNotifications,
    totalUnreads,
    totalReads,
  },
}) => {
  const [notificationType, setNotificationType] = useState('like');
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getUnreadNotification();
    getNotificationByType(1, 10, notificationType);
    ReadAllNotification(notificationType);
  }, [getUnreadNotification, getNotificationByType, ReadAllNotification, notificationType]);
  // const [searchText, setSearchText] = useState('');
  // const [profilePage, setProfilePage] = useState('');
  // const [profilePageSize, setProfilePageSize] = useState('');

  const asyncGetNotificationByType = async (page, pageSize, type) => {
    await getNotificationByType(page, pageSize, type);
  };
  const handlePageChange = async (page, pageSize) => {
    // setProfilePage(page);
    // setProfilePageSize(pageSize);
    await getNotificationByType(page, pageSize, notificationType);
    setCurrentPage(page);
  };

  // const onSearch = async (value, _e, info) => {
  //   setSearchText(value);
  //   await getProfiles(profilePage, profilePageSize, value);
  // };

  return (
    <section className="container">
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="large text-light">
            <i className="fa-solid fa-envelope"></i> Inbox
          </h1>

          {/* <Search
            className="my-1"
            placeholder="search user name"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={onSearch}
          /> */}
          <div className="notification-buttons">
            <button
              className={`notification-btn ${notificationType === 'like' ? 'active' : ''}`}
              onClick={() => {
                setNotificationType('like');
                asyncGetNotificationByType(1, 10, 'like');
                setCurrentPage(1);
              }}
            >
              Like{' '}
              {'' +
                (totalUnreadLikeNotifications && totalUnreadLikeNotifications > 0
                  ? `(${totalUnreadLikeNotifications})`
                  : '')}
            </button>
            <button
              className={`notification-btn ${notificationType === 'comment' ? 'active' : ''}`}
              onClick={() => {
                setNotificationType('comment');
                asyncGetNotificationByType(1, 10, 'comment');
                setCurrentPage(1);
              }}
            >
              Comment{' '}
              {'' +
                (totalUnreadCommentNotifications && totalUnreadCommentNotifications > 0
                  ? `(${totalUnreadCommentNotifications})`
                  : '')}
            </button>
            <button
              className={`notification-btn ${notificationType === 'follow' ? 'active' : ''}`}
              onClick={() => {
                setNotificationType('follow');
                asyncGetNotificationByType(1, 10, 'follow');
                setCurrentPage(1);
              }}
            >
              Follow{' '}
              {'' +
                (totalUnreadFollowNotifications && totalUnreadFollowNotifications > 0
                  ? `(${totalUnreadFollowNotifications})`
                  : '')}
            </button>
          </div>
          <div className="profiles">
            {notifications && notifications.length > 0 ? (
              notifications.map((notification) => (
                <NotificationItem key={notification._id} notification={notification} />
              ))
            ) : (
              <h4>No new messages...</h4>
            )}
          </div>
          <div className="m-2">
            <Pagination
              showSizeChanger
              current={currentPage}
              defaultCurrent={1}
              defaultPageSize={10}
              pageSizeOptions={[1, 5, 10, 20]}
              onChange={handlePageChange}
              total={totalNotifications}
            />
          </div>
        </Fragment>
      )}
    </section>
  );
};

Notifications.propTypes = {
  getUnreadNotification: PropTypes.func.isRequired,
  getReadNotification: PropTypes.func.isRequired,
  getNotificationByType: PropTypes.func.isRequired,
  ReadAllNotification: PropTypes.func.isRequired,
  notification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, {
  getUnreadNotification,
  getReadNotification,
  getNotificationByType,
  ReadAllNotification,
})(Notifications);
