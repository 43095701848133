function extractFrame(videoFile, frameNumber = 30, fps = 30, format = 'jpeg') {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.setAttribute('playsInline', '');
    video.setAttribute('webkit-playsinline', '');
    video.style.display = 'none'; // Ensure the video is in the DOM
    document.body.appendChild(video); // Append the video to the body
    video.muted = true;
    video.src = URL.createObjectURL(videoFile);

    video.onloadeddata = () => {
      video.currentTime = frameNumber / fps;
    };

    video.onseeked = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        document.body.removeChild(video); // Clean up: remove the video element
        resolve(blob);
      }, `image/${format}`);
    };

    video.onerror = () => {
      document.body.removeChild(video); // Clean up: remove the video element
      reject('Video loading error.');
    };

    video.load(); // Load and start processing the video
  });
}

export default extractFrame;
