import React, { Fragment, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUser,
  faCat,
  faGear,
  faRightFromBracket,
  faEnvelope,
  faUserFriends,
  faUserDoctor,
} from '@fortawesome/free-solid-svg-icons';
import icon from '../../img/Icon-512x512.png';

const Section = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px; // Adjust the height as needed
  background-color: var(--primary-color); // Changed to a darker shade based on your screenshot
  color: white; // Text color changed to white for contrast
  position: fixed; // This will make the navbar stick to the top of the page
  top: 0;
  width: 100%; // Ensure the navbar stretches across the full width
  backdrop-filter: blur(10px); // This creates the frosted glass effect
  -webkit-backdrop-filter: blur(10px); // For Safari support
  z-index: 1000; // Ensure the navbar stays on top of other content
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional shadow for depth
  border-bottom: solid 1px var(--dark-color);
  position: fixed;
  z-index: 100;
  opacity: 0.9;
  @media (max-width: 768px) {
    height: 60px; // Adjust the height as needed
  }
`;

const Container = styled.div`
  width: 90%; // Adjust the width as needed
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-size: 2.5rem; // Adjust the font size as needed
  gap: 10px; // Adjust the gap as needed
  font-family: 'Futura'; // Adjust the font family as needed
  font-weight: bold; // Adjust the font weight as needed
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.9); // Optional text shadow for contrast
  cursor: pointer;
  &:hover {
    color: #d3d3d3;
  }
  @media (max-width: 768px) {
    font-size: 2rem; // Adjust the font size as needed
  }
`;

const Logo = styled.img`
  max-height: 70px; // Adjust the logo size as needed
  width: auto; // Adjust the logo size as needed
  @media (max-width: 768px) {
    max-height: 50px; // Adjust the logo size as needed
  }
`;

const NavItems = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 1.3rem; // Adjust the font size as needed
  gap: 30px; // Adjust the gap as needed
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileNavItems = styled.div`
  display: none;
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; // 2/3 of the screen height
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 20px;
    transform: ${(props) => (props.open ? 'translateX(50%)' : 'translateX(130%)')};
    transition: transform 0.7s ease-in;
    -webkit-backdrop-filter: blur(10px); // For Safari support
    backdrop-filter: blur(10px); // This creates the frosted glass effect
    z-index: 99; // Just below the burger to allow it to be on top
  }
`;

const NavItem = styled.li`
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  position: relative; // Position relative for absolute pseudo-elements
  padding: 0 20px; // Increase padding to create larger gaps
  justify-content: space-between;
  &:hover {
    color: #d3d3d3;
    & > div {
      display: flex;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -10px; // Position the underline below the text
      left: 0;
      width: 100%;
      height: 3px;
      background: #fff;
      transform: scaleX(1); // Start with the underline fully visible
      transition: transform 0.3s ease-in-out;
    }
  }

  &.active {
    color: #d3d3d3;
    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 3px;
      background: #fff;
      transform: scaleX(1); // Ensure the underline is always visible for the active item
    }
  }

  &:not(.active)::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 3px;
    background: #fff;
    transform: scaleX(0); // Hide the underline for non-active items
    transition: transform 0.3s ease-in-out;
  }
`;

const MobileNavItem = styled.a`
  color: ${(props) => (props.$isActiveToShow ? 'black' : 'white')};
  background-color: ${(props) => (props.$isActiveToShow ? 'white' : 'transparent')};
  border-radius: 15px; // Add a border-radius for rounded corners
  padding: 10px 20px; // Add some padding around the text
  font-size: 1.5em;
  cursor: pointer;
  display: flex; // Use flexbox to align items
  align-items: center; // Center the items vertically
  justify-content: left; // Center the items horizontally
  transition: all 0.3s ease; // Smooth transition for color and background-color

  // Icon styling
  & svg {
    margin-right: 8px; // Space between the icon and the text
  }
`;

const Burger = styled.div`
  @media (max-width: 768px) {
    width: 30px;
    height: 25px;
    position: fixed;
    top: 20px;
    right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    opacity: ${(props) => (props.open ? 0 : 1)};
    visibility: ${(props) => (props.open ? 'hidden' : 'visible')};
    transition: opacity 0.3s, visibility 0.3s;
    & div {
      transition: transform 0.3s ease-in-out;
      // Add the rotating animation here
      transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
    }
  }
  // This will rotate the lines for open state
`;

const BurgerLine = styled.div`
  width: 100%;
  height: 3px;
  background-color: #fff;
`;
const CloseIconWrapper = styled.div`
  @media (max-width: 768px) {
    width: 40px; // Slightly larger to create a white circle around the X
    height: 40px;
    position: fixed;
    top: 12px;
    right: 30px;
    z-index: 1500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white; // White color for the circle
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    transform: ${(props) => (props.open ? 'scale(1)' : 'scale(0)')};
  }
`;

const CloseIcon = styled.div`
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #333; // Deep color for the X itself
    }
    &::before {
      transform: rotate(45deg);
      transition: transform 0.3s ease-in-out;
    }
    &::after {
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;
    }
  }
`;
const TextSpan = styled.span`
  margin-left: 8px; // Adjust the margin as needed
`;

const UserAvatar = styled.img`
  height: 35px; // Adjust size as needed
  width: 35px; // Adjust size as needed
  border-radius: 50%; // Makes the image circular
  object-fit: cover; // Ensures the image covers the full area without distortion
  border: 2px solid white; // Optional: adds a border around the avatar
`;

const DropdownMenu = styled.div`
  position: absolute;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); // 加深阴影，增强立体感
  z-index: 1;
  margin-top: 10px;
  top: 100%;
  left: 0;
  width: 200px; // 指定一个宽度
  transition: all 0.5s ease; // 平滑过渡效果
  max-height: 0;
  opacity: 0;

  &.show {
    max-height: 500px; // 足够展示所有内容
    opacity: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: -20px; // 确保箭头不被裁切，调整位置
    left: 50%; // 中心对齐
    transform: translateX(-50%); // 居中三角形
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white; // 确保箭头颜色与菜单背景匹配
  }
`;

const DropdownItem = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  transition: background-color 0.2s;

  &:hover {
    background-color: #ddd;
  }
`;

// 更新NavItem组件
const NavItemWithDropdown = styled(NavItem)`
  position: relative;
  &:hover ${DropdownMenu} {
    display: block;
    max-height: 500px; // 展开至足够高度以显示所有内容
    opacity: 1;
  }
`;

const NotificationBubble = styled.div`
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0 6px;
  font-size: 0.7rem;
  position: absolute;
  top: 4px;
  right: 10px;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  font-weight: bold;
`;

const NotificationBubbleMobile = styled.span`
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0 6px;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  font-weight: bold;
  margin-left: 10px; // Adjust positioning based on your layout
`;

const Navbar = ({
  auth: { user, isAuthenticated },
  logout,
  notification: {
    totalUnreadLikeNotifications,
    totalUnreadCommentNotifications,
    totalUnreadFollowNotifications,
    totalUnreads,
  },
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState('Home');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname;
    if (path === '/' || path === '/posts') {
      setActiveNavItem('Home');
    } else if (path === '/profiles') {
      setActiveNavItem('Kats');
    } else if (path === '/login') {
      setActiveNavItem('Login');
    } else if (path === `/profile/${user?._id}` || path === '/dashboard') {
      setActiveNavItem('Me');
    } else if (path === '/inbox') {
      setActiveNavItem('Inbox');
    } else if (path === '/posts-following') {
      setActiveNavItem('Follow');
    } else if (
      path.includes('/chatbot') ||
      path === '/chatbots' ||
      path.includes('ai-vet') ||
      path.includes('vetgpt')
    ) {
      setActiveNavItem('VetGPT');
    }
  }, [location, user]);

  // const handleLogout = () => {
  //   const confirm = window.confirm('Are you sure you want to logout?');

  //   if (confirm) {
  //     logout();
  //   } else {
  //     // console.log('Account deletion canceled.');
  //   }
  // };

  const handleNavItemClick = (e, target, path) => {
    e.preventDefault();
    setActiveNavItem(target);
    navigate(path);
    // setActiveNavItem(target.slice(1)); // Set the active nav item based on the target's hash value
    if (target === 'Logout') {
      logout();
    }
    // const targetSection = document.querySelector(target);
    // targetSection.scrollIntoView({ behavior: 'smooth' });
    setMobileMenuOpen(false);
  };

  const authLinks = (
    <>
      <Section>
        <Container>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <LogoWrapper>
              <Logo src={icon} alt="Logo" />
              KatFun
            </LogoWrapper>
          </Link>

          {/* <h1>
            <Link to={isAuthenticated ? '/posts' : '/'} style={{ fontFamily: 'Futura' }}>
              KatFun
            </Link>
          </h1> */}
          <NavItems>
            {[
              { name: 'Home', icon: faHome, path: '/' },
              { name: 'VetGPT', icon: faUserDoctor, path: '/vetgpt' },
              { name: 'Kats', icon: faCat, path: '/profiles' },
              { name: 'Follow', icon: faUserFriends, path: '/posts-following' },
              {
                name: 'Inbox',
                icon: faEnvelope,
                path: '/inbox',
                notification:
                  totalUnreadLikeNotifications +
                  totalUnreadCommentNotifications +
                  totalUnreadFollowNotifications,
              },
              { name: 'Me', icon: faUser, path: `/profile/${user?._id}` },
              // ... add the rest of your items here with the appropriate icons
            ].map((item, index) => (
              <React.Fragment key={item.name + index}>
                {item.name === 'Me' && user ? (
                  <NavItemWithDropdown
                    key={item.name}
                    className={activeNavItem === item.name ? 'active' : ''}
                  >
                    {user.avatar ? (
                      <UserAvatar
                        src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + user.avatar}
                        alt="User Avatar"
                      />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={item.icon} />
                      </>
                    )}
                    <TextSpan>{item.name}</TextSpan>
                    <DropdownMenu>
                      <DropdownItem to={`/profile/${user?._id}`}>Profile</DropdownItem>
                      <DropdownItem to="/dashboard">Dashboard</DropdownItem>
                      <DropdownItem onClick={logout}>Logout</DropdownItem>
                    </DropdownMenu>
                  </NavItemWithDropdown>
                ) : (
                  <Link
                    to={item.path}
                    key={item.name}
                    onClick={(e) => handleNavItemClick(e, `${item.name}`, item.path)}
                  >
                    <NavItem className={activeNavItem === item.name ? 'active' : ''}>
                      <FontAwesomeIcon icon={item.icon} />
                      <TextSpan>{item.name}</TextSpan>
                      {item.name === 'Inbox' && (
                        <NotificationBubble>
                          {item.notification && item.notification > 0 ? item.notification : 0}
                        </NotificationBubble>
                      )}
                    </NavItem>
                  </Link>
                )}
              </React.Fragment>
            ))}
          </NavItems>
          <CloseIconWrapper open={mobileMenuOpen} onClick={() => setMobileMenuOpen(false)}>
            <CloseIcon />
          </CloseIconWrapper>

          <Burger open={mobileMenuOpen} onClick={() => setMobileMenuOpen(true)}>
            <BurgerLine />
            <BurgerLine />
            <BurgerLine />
          </Burger>
        </Container>
      </Section>
      <MobileNavItems open={mobileMenuOpen}>
        {[
          { name: 'Home', icon: faHome, path: '/' },
          { name: 'VetGPT', icon: faUserDoctor, path: '/vetgpt' },
          { name: 'Kats', icon: faCat, path: '/profiles' },
          { name: 'Follow', icon: faUserFriends, path: '/posts-following' },
          {
            name: 'Inbox',
            icon: faEnvelope,
            path: '/inbox',
            notification:
              totalUnreadLikeNotifications +
              totalUnreadCommentNotifications +
              totalUnreadFollowNotifications,
          },
          { name: 'Settings', icon: faGear, path: '/dashboard' },
          { name: 'About Us', icon: faUser, path: `/contact` },
          { name: 'Logout', icon: faRightFromBracket, path: '/' },
        ].map((item) => (
          <MobileNavItem
            key={item.name}
            onClick={(e) => handleNavItemClick(e, `${item.name}`, item.path)}
            $isActiveToShow={activeNavItem === item.name}
          >
            <FontAwesomeIcon icon={item.icon} />
            <TextSpan>{item.name}</TextSpan>
            {item.name === 'Inbox' && (
              <NotificationBubbleMobile>
                {item.notification && item.notification > 0 ? item.notification : 0}
              </NotificationBubbleMobile>
            )}
          </MobileNavItem>
        ))}
      </MobileNavItems>
    </>
  );

  const guestLinks = (
    <>
      <Section>
        <Container>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <LogoWrapper>
              <Logo src={icon} alt="Logo" />
              KatFun
            </LogoWrapper>
          </Link>

          {/* <h1>
            <Link to={isAuthenticated ? '/posts' : '/'} style={{ fontFamily: 'Futura' }}>
              KatFun
            </Link>
          </h1> */}
          <NavItems>
            {[
              { name: 'Home', icon: faHome, path: '/' },
              { name: 'VetGPT', icon: faUserDoctor, path: '/vetgpt' },
              { name: 'Kats', icon: faCat, path: '/profiles' },
              { name: 'Login', icon: faUser, path: '/login' },
              // ... add the rest of your items here with the appropriate icons
            ].map((item) => (
              <Link
                to={item.path}
                key={item.name}
                onClick={(e) => handleNavItemClick(e, `${item.name}`, item.path)}
              >
                <NavItem key={item.name} className={activeNavItem === item.name ? 'active' : ''}>
                  <FontAwesomeIcon icon={item.icon} />
                  <TextSpan>{item.name}</TextSpan>
                </NavItem>
              </Link>
            ))}
          </NavItems>
          <CloseIconWrapper open={mobileMenuOpen} onClick={() => setMobileMenuOpen(false)}>
            <CloseIcon />
          </CloseIconWrapper>

          <Burger open={mobileMenuOpen} onClick={() => setMobileMenuOpen(true)}>
            <BurgerLine />
            <BurgerLine />
            <BurgerLine />
          </Burger>
        </Container>
      </Section>
      <MobileNavItems open={mobileMenuOpen}>
        {[
          { name: 'Home', icon: faHome, path: '/' },
          { name: 'VetGPT', icon: faUserDoctor, path: '/vetgpt' },
          { name: 'Kats', icon: faCat, path: '/profiles' },
          { name: 'Login', icon: faUser, path: '/login' },
        ].map((item) => (
          <MobileNavItem
            key={item.name}
            onClick={(e) => handleNavItemClick(e, `${item.name}`, item.path)}
            $isActiveToShow={activeNavItem === item.name}
          >
            <FontAwesomeIcon icon={item.icon} /> {item.name}
          </MobileNavItem>
        ))}
      </MobileNavItems>
    </>
  );

  return <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>;
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  notification: state.notification,
});

export default connect(mapStateToProps, { logout })(Navbar);
