import { setAlert } from './alert';

import {
  GET_JSONL,
  GET_JSONL_MESSAGES,
  CREATE_JSONL_MESSAGES,
  DELETE_JSONL_MESSAGES,
  UPDATE_JSONL_MESSAGES,
  CLEAR_JSONL,
} from './types';

import { v4 as uuidv4 } from 'uuid';

// Get all jsonl
export const getJsonl = (messagesList) => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_JSONL,
    });
    const messagesListWithId = messagesList.map((message, index) => ({
      ...message,
      _id: uuidv4(),
    }));
    dispatch({
      type: GET_JSONL,
      payload: messagesListWithId,
    });
    dispatch(setAlert('Jsonl file uploaded', 'success'));
  } catch (err) {
    dispatch(setAlert('Error', 'error'));
  }
};

// Get all messages
export const getJsonlMessages = (messages) => async (dispatch) => {
  try {
    const messagesWithId = messages.map((message, index) => ({
      ...message,
      _id: index,
    }));
    dispatch({
      type: GET_JSONL_MESSAGES,
      payload: messagesWithId,
    });
    dispatch(setAlert('Jsonl file uploaded', 'success'));
  } catch (err) {
    dispatch(setAlert('Error', 'error'));
  }
};

// Create messages
export const createJsonlMessages = (messages) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_JSONL_MESSAGES,
      payload: messages,
    });
    dispatch(setAlert('New Messages Created', 'success'));
  } catch (err) {
    dispatch(setAlert('Error', 'error'));
  }
};

// Delete messages
export const deleteJsonlMessages = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_JSONL_MESSAGES,
      payload: id,
    });
    dispatch(setAlert('Messages Deleted', 'success'));
  } catch (err) {
    dispatch(setAlert('Error', 'error'));
  }
};

// Update messages
export const updateJsonlMessages = (chatId, messages) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_JSONL_MESSAGES,
      payload: { _id: chatId, messages },
    });
    dispatch(setAlert('Messages Updated', 'success'));
  } catch (err) {
    dispatch(setAlert('Error', 'error'));
  }
};
