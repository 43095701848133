import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

const VetbotRoute = ({ component: Component, auth: { user, isAuthenticated, loading } }) => {
  if (loading)
    return (
      <>
        <section className="container">
          <Spinner />
        </section>
      </>
    );
  if (isAuthenticated && user?.grantedAccess?.vetbot) return <Component />;

  return <Navigate to="/vetgpt" />;
};

VetbotRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(VetbotRoute);
