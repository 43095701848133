import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import timeSince from '../../utils/calculateDate';
import { connect } from 'react-redux';
import { addLike, removeLike, deletePost, changeVisiblePost } from '../../actions/post';
import { Dropdown, message } from 'antd';
import FollowButton from '../general/follow-buttun/FollowButton';
import spinner from '../layout/spinner.gif';
import './PostItem.css';

const PostItem = ({
  addLike,
  removeLike,
  deletePost,
  changeVisiblePost,
  auth,
  post: {
    _id,
    title,
    name,
    avatar,
    user,
    likes,
    likesCount,
    created,
    profile,
    text,
    pictures,
    cat,
    isLiked,
    video,
    visible,
  },
}) => {
  const navigate = useNavigate();
  const [selectedImg, setSelectedImg] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loaded, setLoaded] = useState(false);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const handleOnClickLikePost = async () => {
    if (isDebouncing) return;
    if (!auth.isAuthenticated) {
      messageApi.open({
        type: 'error',
        content: 'Please login to like the post',
      });
      setTimeout(() => {
        navigate('/login');
      }, 500);
    }
    setIsDebouncing(true);
    if (isLiked) {
      await removeLike(_id);
    } else {
      await addLike(_id);
    }
    setTimeout(() => setIsDebouncing(false), 500);
  };

  const handleImageClick = (imgId) => {
    setSelectedImg(imgId);
  };

  const handleBackgroundClick = () => {
    setSelectedImg(null);
  };

  const handleVideoClick = (imgId) => {};

  const handleDeletedClick = async (id) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete the post?');
      if (confirmDelete) {
        const isDelete = await deletePost(id);
        if (isDelete) {
          messageApi.open({
            type: 'success',
            content: 'Post deleted',
          });
          navigate('/posts');
        } else {
          messageApi.open({
            type: 'error',
            content: 'Post deleted failed',
          });
        }
      } else {
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  const handleVisibleClick = async (id) => {
    try {
      if (isDebouncing) return;
      setIsDebouncing(true);
      const isVisible = visible ? true : false;
      let confirmChange;
      if (isVisible) {
        confirmChange = window.confirm('Are you sure you want to make the post invisible?');
      } else {
        confirmChange = window.confirm('Are you sure you want to visible the post?');
      }
      if (confirmChange) {
        const isChange = await changeVisiblePost(id);
        if (isChange) {
          messageApi.open({
            type: 'success',
            content: 'Post visible changed',
          });
        } else {
          messageApi.open({
            type: 'error',
            content: 'Post visible changed failed',
          });
        }
      } else {
      }
      setTimeout(() => setIsDebouncing(false), 500);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const items = [
    {
      label: (
        <div onClick={() => {}}>
          <i className="fa-solid fa-pen-to-square"></i> Edit
        </div>
      ),
      key: '1',
    },
    {
      label: (
        <div
          onClick={() => {
            handleDeletedClick(_id);
          }}
        >
          <i className="fa-solid fa-trash-can"></i> Delete
        </div>
      ),
      key: '2',
      danger: true,
    },
    {
      label: (
        <>
          <div
            onClick={() => {
              handleVisibleClick(_id);
            }}
          >
            {visible ? (
              <>
                <i className="fa-solid fa-eye-slash"></i> Invisible
              </>
            ) : (
              <>
                <i className="fa-regular fa-eye"></i> Visible
              </>
            )}
          </div>
        </>
      ),
      key: '3',
    },
  ];

  return (
    <Fragment>
      {contextHolder}
      <div className="post-container bg-light p-1 my-1">
        <div className="post-avatar-name-field">
          {cat ? (
            <Link to={`/profile/${user}`}>
              {cat.avatar ? (
                <img
                  className="avatar-post-img"
                  src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + cat.avatar}
                  alt=""
                />
              ) : (
                <img
                  className="avatar-post-img"
                  src={
                    process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT +
                    'public/user/avatar/cat-default-avatar.png'
                  }
                  alt=""
                />
              )}
            </Link>
          ) : (
            <Link to={`/profile/${user}`}>
              {avatar ? (
                <img
                  className="avatar-post-img"
                  src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + avatar}
                  alt=""
                />
              ) : (
                <img
                  className="avatar-post-img"
                  src={
                    process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT +
                    'public/user/avatar/default-avatar.jpeg'
                  }
                  alt=""
                />
              )}
            </Link>
          )}
          <div className="post-user-name">
            <Link to={`/profile/${user}`} style={{ color: 'var(--primary-dark-color)' }}>
              <h3>{cat && cat.name ? cat.name : name}</h3>
            </Link>
          </div>
          <div className="post-user-follow m-1">
            {auth?.user?._id && auth?.user?._id !== user && (
              <FollowButton user={user} buttonText={'Follow'} />
            )}
          </div>
          <div className="post-user-option">
            {!auth.loading && user === auth?.user?._id ? (
              <Dropdown
                menu={{
                  items,
                }}
              >
                <div className="icon-box">
                  <i className="fa-solid fa-ellipsis"></i>
                </div>
              </Dropdown>
            ) : (
              <Dropdown
                menu={{
                  items: [
                    {
                      label: (
                        <div onClick={() => {}}>
                          <i className="fa-solid fa-star" style={{ color: '#e5d957' }} /> Collect
                        </div>
                      ),
                      key: '1',
                    },
                  ],
                }}
              >
                <div className="icon-box">
                  <i className="fa-solid fa-ellipsis"></i>
                </div>
              </Dropdown>
            )}
          </div>
        </div>

        <div className="post-content">
          {!video && pictures && pictures.length > 0 && (
            <div className="image-container">
              {pictures.map((picture) => (
                <div
                  key={picture._id}
                  className={`image ${selectedImg === picture._id ? 'zoom' : ''}`}
                  onClick={() => handleImageClick(picture._id)}
                >
                  <img
                    src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + picture.picture}
                    alt={picture._id}
                  />
                </div>
              ))}
            </div>
          )}
          {video && (
            <>
              {!loaded && (
                <div className="video-container">
                  <img
                    src={spinner} // Replace with your spinner's local or imported path
                    alt="Loading..."
                    className="spinner"
                  />
                </div>
              )}
              <div className="video-container" onClick={handleVideoClick}>
                <video
                  controls
                  onLoadedMetadata={() => setLoaded(true)}
                  playsInline
                  preload="auto"
                  autoPlay
                  className="video-js vjs-big-play-centered"
                  data-setup="{}"
                  src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + video}
                  style={{ display: loaded ? 'block' : 'none' }}
                ></video>
              </div>
            </>
          )}
          {selectedImg && !video && (
            <div className="zoom-container" onClick={handleBackgroundClick}>
              <img
                src={
                  process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT +
                  pictures.find((p) => p._id === selectedImg).picture
                } // 找到选中的图片
                alt={selectedImg}
                className="zoom-image"
                style={{ transform: 'scale(1.7)' }}
              />
            </div>
          )}
          <h3 className="post-text">{text}</h3>
          <p className="post-item-date my">Posted on {timeSince(created)}</p>
          <div className="post-like-collection-field ">
            <div className="icon-box" onClick={handleOnClickLikePost}>
              {isLiked ? (
                <i className="fa-solid fa-heart" style={{ color: '#ed0202' }} />
              ) : (
                <i className="fa-regular fa-heart"></i>
              )}
            </div>{' '}
            <span>{likesCount > 0 && <span>{likesCount}</span>}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
  changeVisiblePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addLike, removeLike, deletePost, changeVisiblePost })(
  PostItem,
);
