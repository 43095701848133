import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt, faCat, faUser } from '@fortawesome/free-solid-svg-icons';

const ContactSection = styled.section`
  color: #504658; // dark purple text for contrast
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContactHeader = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 30px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoItem = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const ContactLink = styled.a`
  text-decoration: none;
  color: #504658; // dark purple text
  &:hover {
    color: #8860d0; // brighter purple on hover
  }
`;

const Contact = () => {
  return (
    <>
      <section className="container">
        <ContactSection>
          <ContactHeader>Contact Me</ContactHeader>
          <ContactInfo>
            <InfoItem>
              <Icon icon={faEnvelope} />
              <ContactLink href="haoj1.bian@gmail.com">haoj1.bian@gmail.com</ContactLink>
            </InfoItem>
            <InfoItem>
              <Icon icon={faEnvelope} />
              <ContactLink href="hbian@wisc.edu">hbian@wisc.edu</ContactLink>
            </InfoItem>
            <InfoItem>
              <Icon icon={faCat} />
              <ContactLink href="haoji.bian@katfun.com">haoji.bian@katfun.com</ContactLink>
            </InfoItem>
            <InfoItem>
              <Icon icon={faMapMarkerAlt} />
              <span>Chicago, IL</span>
            </InfoItem>
            <InfoItem>
              <Icon icon={faUser} />
              <ContactLink href="https://haoj1.github.io" target="_blank">
                Know more about me
              </ContactLink>
            </InfoItem>
          </ContactInfo>
        </ContactSection>
      </section>
    </>
  );
};

export default Contact;
