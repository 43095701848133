import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import ProfileCat from './ProfileCat';
import { getProfileById } from '../../actions/profile';
import { getCatsByUserId } from '../../actions/cat';
import { getUserPostsById } from '../../actions/post';
import ProfilePost from './ProfilePost';
import { Pagination, Input } from 'antd';
import icon from '../../img/CuteIcon.png';

const { Search } = Input;

const Profile = ({
  getProfileById,
  getCatsByUserId,
  getUserPostsById,
  profile: { profile, loading },
  auth,
  cat: { cats },
  post: { posts, totalPosts },
}) => {
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      await getProfileById(id);
      await getCatsByUserId(id);
      await getUserPostsById(1, 4, '', id);
      setIsLoaded(true);
    };
    fetchData();
  }, [getProfileById, getCatsByUserId, getUserPostsById, id]);
  useEffect(() => {
    if (profile) {
      document.title = `${profile.name}'s Profile`;
    } else {
      document.title = 'Profile';
    }
  }, [profile]);
  const [searchText, setSearchText] = useState('');
  const [postPage, setPostPage] = useState(1);
  const [postPageSize, setPostPageSize] = useState(4);

  const handlePageChange = async (page, pageSize) => {
    setPostPage(page);
    setPostPageSize(pageSize);
    await getUserPostsById(page, pageSize, searchText, id);
  };

  const onSearch = async (value, _e, info) => {
    setSearchText(value);
    await getUserPostsById(postPage, postPageSize, value, id);
  };
  const NoProfile = ({ icon }) => (
    <>
      <img className="profiles-icon-medium" src={icon} alt=""></img>
      <p>You have not yet setup a profile, please add some info</p>
      <Link to="/create-profile" className="btn btn-primary my-1">
        Create Profile
      </Link>
    </>
  );

  // 提取Posts部分
  // const ProfilePosts = ({ totalPosts, handlePageChange, onSearch }) => (
  //   <div className="profile-post bg-white p-2">
  //     <h2 className="text-light">Posts</h2>
  //     {posts.length > 0 ? (
  //       <>
  //         <ProfilePost />
  //         <div className="my-1">
  //           <Pagination
  //             current={postPage}
  //             showSizeChanger
  //             defaultCurrent={1}
  //             defaultPageSize={4}
  //             pageSizeOptions={[1, 5, 10, 20]}
  //             onChange={handlePageChange}
  //             total={totalPosts}
  //           />
  //         </div>
  //         <Search
  //           className="my-1"
  //           placeholder="search posts"
  //           allowClear
  //           enterButton="Search"
  //           size="large"
  //           onSearch={onSearch}
  //         />
  //       </>
  //     ) : (
  //       <>
  //         <h3>Login to see posts</h3>
  //         <Link to="/login" className="btn btn-gray my-1">
  //           Login
  //         </Link>
  //       </>
  //     )}
  //   </div>
  // );

  const ProfileCats = ({ cats }) => (
    <div className="profile-cat bg-white p-2">
      <h2 className="text-light">Cats</h2>
      {cats.length > 0 ? (
        <Fragment>
          {cats.map((cat) => (
            <ProfileCat id={`cat-${cat._id}`} key={cat._id} cat={cat} />
          ))}
        </Fragment>
      ) : (
        <h4>No Cats</h4>
      )}
    </div>
  );

  return (
    <section className="container">
      {!isLoaded ? (
        <Spinner />
      ) : (
        <>
          {isLoaded &&
          !profile &&
          auth.isAuthenticated &&
          auth?.user?._id &&
          auth.user._id === id.toString() ? (
            <NoProfile icon={icon} />
          ) : (
            <>
              {profile ? (
                <Fragment>
                  {auth.isAuthenticated &&
                    auth.loading === false &&
                    auth?.user?._id &&
                    auth.user._id !== profile.user._id && (
                      <Link to="/profiles" className="btn btn-light">
                        Back To Profiles
                      </Link>
                    )}
                  <div className="profile-grid my-1">
                    <ProfileTop profile={profile} />
                    <ProfileAbout profile={profile} />
                    <div className="profile-post bg-white p-2">
                      <h2 className="text-light">Posts</h2>
                      {auth.isAuthenticated && posts?.length > 0 ? (
                        <>
                          <ProfilePost />
                          <div className="my-1">
                            <Pagination
                              current={postPage}
                              showSizeChanger
                              defaultCurrent={1}
                              defaultPageSize={4}
                              pageSizeOptions={[1, 5, 10, 20]}
                              onChange={handlePageChange}
                              total={totalPosts}
                            />
                          </div>
                          <Search
                            className="my-1"
                            placeholder="search posts"
                            allowClear
                            enterButton="Search"
                            size="large"
                            onSearch={onSearch}
                          />
                        </>
                      ) : (
                        <>
                          {auth.isAuthenticated ? (
                            <>
                              <h3>No Posts</h3>
                            </>
                          ) : (
                            <>
                              <h3>Login to see posts</h3>
                              <Link to="/login" className="btn btn-gray my-1">
                                Login
                              </Link>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <ProfileCats cats={cats} /> {/* 使用 ProfileCats 组件 */}
                  </div>
                </Fragment>
              ) : (
                <Navigate to="/" replace />
              )}
            </>
          )}
        </>
      )}
    </section>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  getCatsByUserId: PropTypes.func.isRequired,
  getUserPostsById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  cat: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  cat: state.cat,
  post: state.post,
});

export default connect(mapStateToProps, { getProfileById, getCatsByUserId, getUserPostsById })(
  Profile,
);
