import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getChats, deleteChat, updateChat, addChat } from '../../actions/chatbot';
import styles from './ChatbotList.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

const ChatbotList = ({ auth, chatbot, getChats, deleteChat, updateChat, addChat }) => {
  const [editing, setEditing] = useState(null);
  const [newTitle, setNewTitle] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    getChats();
  }, [getChats]);

  const handleDelete = (chatId) => {
    if (window.confirm('Are you sure you want to delete this chatbot?')) {
      deleteChat(chatId);
    }
  };
  const handleAddChat = async () => {
    const newChat = {
      title: 'New Chat', // 你可以修改这里来设置一个默认的标题
      user: auth.user._id, // 假设auth对象有user及其_id属性
    };
    const res = await addChat(newChat);
    if (res.success) {
      navigate(`/chatbot/${res.data._id}`);
    }
  };

  const handleEdit = (chat) => {
    setEditing(chat._id);
    setNewTitle(chat.title);
  };

  const handleUpdate = (chatId) => {
    updateChat(chatId, newTitle);
    setEditing(null);
  };

  const handleChangeTitle = (e) => {
    setNewTitle(e.target.value);
  };
  const groupChatsByDate = (chats) => {
    const groups = chats.reduce((groups, chat) => {
      const date = new Date(chat.updatedAt).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(chat);
      return groups;
    }, {});

    // 将对象转换为数组，并按日期降序排序
    return Object.keys(groups)
      .sort((a, b) => new Date(b) - new Date(a))
      .map((date) => {
        return {
          date,
          chats: groups[date],
        };
      });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const yesterday = new Date(now);
    yesterday.setDate(yesterday.getDate() - 1);

    // 如果日期是今天
    if (date.toDateString() === now.toDateString()) {
      return 'Today';
    }
    // 如果日期是昨天
    else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    }
    // 其他情况，显示完整日期
    else {
      return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
  };

  const chatGroups = groupChatsByDate(chatbot.chats);
  const chatsList = chatGroups.map((group) => (
    <div key={group.date}>
      <div className={styles.dateHeader}>{formatDate(group.date)}</div>
      {group.chats.map((chat) => (
        <div key={chat._id} className={styles.chatItem}>
          {editing === chat._id ? (
            <input
              type="text"
              value={newTitle}
              onChange={handleChangeTitle}
              className={styles.titleInput}
            />
          ) : (
            <Link to={`/chatbot/${chat._id}`} className={styles.linkStyle}>
              <span className={styles.chatTitle}>{chat.title}</span>
            </Link>
          )}
          <div className={styles.actions}>
            {editing === chat._id ? (
              <button onClick={() => handleUpdate(chat._id)} className={styles.Button}>
                <FontAwesomeIcon icon={faEdit} /> Save
              </button>
            ) : (
              <button onClick={() => handleEdit(chat)} className={styles.Button}>
                <FontAwesomeIcon icon={faEdit} /> Rename
              </button>
            )}
            <button onClick={() => handleDelete(chat._id)} className={styles.trashButton}>
              <FontAwesomeIcon icon={faTrash} /> Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  ));

  return (
    <div className="container">
      <button onClick={handleAddChat} className={styles.addButton}>
        <FontAwesomeIcon icon={faPlus} /> Start a New AI Vet Chat
      </button>
      {chatbot.isLoadingAllChats ? (
        <p>Loading chats...</p>
      ) : (
        <div className={styles.chatList}>{chatsList}</div>
      )}
    </div>
  );
};

ChatbotList.propTypes = {
  auth: PropTypes.object.isRequired,
  chatbot: PropTypes.object.isRequired,
  getChats: PropTypes.func.isRequired,
  deleteChat: PropTypes.func.isRequired,
  updateChat: PropTypes.func.isRequired,
  addChat: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chatbot: state.chatbot,
});

export default connect(mapStateToProps, { getChats, deleteChat, updateChat, addChat })(ChatbotList);
