import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination } from 'antd';
import { getActivationCodes, approveAccess } from '../../actions/chatbot';
import styles from './VetbotManagement.module.css';

const VetbotManagement = ({
  auth: { user },
  chatbot: { activationCodes },
  getActivationCodes,
  approveAccess,
}) => {
  const [searchEmail, setSearchEmail] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  useEffect(() => {
    getActivationCodes(currentPage, pageSize, searchEmail);
  }, [currentPage, searchEmail, getActivationCodes]);
  const handleSearch = (e) => {
    setSearchEmail(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    getActivationCodes(page, pageSize, searchEmail);
    setCurrentPage(page);
  };

  const handleApprove = (userId, email) => {
    approveAccess(userId, email);
  };
  return (
    <section className="container">
      <input
        type="text"
        value={searchEmail}
        onChange={handleSearch}
        placeholder="Search by email"
        className={styles.inputSearch}
      />
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Code</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {activationCodes &&
            activationCodes.map((code) => (
              <tr key={code._id} className={code?.isApproved ? styles.approved : ''}>
                <td>{code?.user?.email}</td>
                <td>{code.code}</td>
                <td>
                  <button
                    onClick={() => handleApprove(code.user._id, code?.user?.email)}
                    className={styles.button}
                  >
                    Approve
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        onChange={handlePageChange}
        total={50} // Total should come from the server
        // className={styles.pagination} // Add if you want to style the pagination
      />
    </section>
  );
};

VetbotManagement.propTypes = {
  auth: PropTypes.object.isRequired,
  chatbot: PropTypes.object.isRequired,
  getActivationCodes: PropTypes.func.isRequired,
  approveAccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  chatbot: state.chatbot,
});

export default connect(mapStateToProps, { getActivationCodes, approveAccess })(VetbotManagement);
