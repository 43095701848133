import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { verifyEmail } from '../../../actions/auth';
import styles from './auth.module.css'; // Ensure this path is correct
import icon from '../../../img/CuteIcon.png';

const ResetPwdVerifyEmail = ({ verifyEmail }) => {
  const [code, setCode] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const email = new URLSearchParams(location.search).get('email');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await verifyEmail(email, code);
    if (response) {
      sessionStorage.setItem('registrationStage', 'resetPwd');
      navigate(
        `/reset-password?email=${encodeURIComponent(
          email,
        )}&step=resetPwd&emailToken=${encodeURIComponent(code)}`,
      );
    } else {
      return;
    }
  };

  const obfuscateEmail = (email) => {
    const parts = email.split('@');
    if (parts[0].length > 2) {
      const namePart = parts[0].substring(0, 2) + '*'.repeat(parts[0].length - 2);
      return `${namePart}@${parts[1]}`;
    }
    return email; // Fallback in case the email doesn't follow the expected format
  };

  useEffect(() => {
    const registrationStage = sessionStorage.getItem('registrationStage');
    if (registrationStage === 'resetPwd') {
      navigate('/reset-password-email-submission');
    }
  }, [navigate, location]);

  if (!email || new URLSearchParams(location.search).get('step') !== 'verify') {
    return <Navigate to="/reset-password-email-submission" replace />;
  }

  return (
    <section className="container">
      <section className={styles.authContainer}>
        <img className="profiles-icon-medium " src={icon} alt=""></img>
        <h1 className={styles.largeText}>Enter Verification Code</h1>
        <p className={styles.infoText}>
          <p>
            A verification code has been sent to {email ? obfuscateEmail(email) : 'your email'}.
            Please enter the 6-digit code below to continue.
          </p>
          <p>
            If you did not receive a code, please check your spam folder or{' '}
            <a href="/email-submission">resend the code</a>.
          </p>
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              placeholder="Verification Code"
              name="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className={styles.inputField}
              required
            />
          </div>
          <input type="submit" className={styles.loginButton} value="Verify" />
        </form>
      </section>
    </section>
  );
};

ResetPwdVerifyEmail.propTypes = {
  verifyEmail: propTypes.func.isRequired,
};

export default connect(null, { verifyEmail })(ResetPwdVerifyEmail);
