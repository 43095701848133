import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import CatProfileItem from './CatProfileItem';
import { getProfiles } from '../../actions/profile';
import { getAllCats } from '../../actions/cat';
import { Pagination, Input } from 'antd';
import './Profiles.css';

const { Search } = Input;

const Profiles = ({
  getProfiles,
  getAllCats,
  profile: { profiles, loading, totalProfile },
  cat: { cats, totalCats },
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  let searchQuery = query.get('search') || '';
  let pageQuery = query.get('page') || 1;
  let pageSizeQuery = query.get('pageSize') || 10;
  useEffect(() => {
    getProfiles(pageQuery, pageSizeQuery, searchQuery);
    getAllCats(pageQuery, pageSizeQuery, searchQuery);
  }, [getProfiles, getAllCats, pageQuery, pageSizeQuery, searchQuery]);

  const handlePageChange = async (page, pageSize) => {
    if (searchQuery === null) {
      searchQuery = '';
    }
    navigate(`/profiles?page=${page}&pageSize=${pageSize}&search=${searchQuery}`);
  };

  const onSearch = async (value, _e, info) => {
    if (value === '') {
      navigate(`/profiles?page=${1}&pageSize=${12}`);
    }
    navigate(`/profiles?search=${value}`);
  };

  return (
    <section className="container">
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="profiles-list-header-container">
            <div className="profiles-list-header-search-input">
              <Search
                className="my-1 computer-layout"
                placeholder="search cat name"
                allowClear
                size="large"
                onSearch={onSearch}
              />
            </div>
          </div>
          <Search
            className="my-1 mobile-layout"
            placeholder="search cat name"
            allowClear
            size="large"
            onSearch={onSearch}
          />
          <div className={`profiles `}>
            {/* {profiles.length > 0 ? (
              profiles.map((profile) => <ProfileItem key={profile._id} profile={profile} />)
            ) : (
              <h4>No profiles found...</h4>
            )} */}
            {cats && cats.length > 0 ? (
              cats.map((cat) => <CatProfileItem id={cat._id} key={cat._id} cat={cat} />)
            ) : (
              <h4>No cats found...</h4>
            )}
          </div>
          <div className="m-2">
            <Pagination
              showSizeChanger
              current={pageQuery && Number(pageQuery)}
              defaultCurrent={1}
              defaultPageSize={10}
              pageSizeOptions={[1, 5, 10, 20]}
              onChange={handlePageChange}
              total={totalCats}
            />
          </div>
        </Fragment>
      )}
    </section>
  );
};

Profiles.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  getAllCats: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  cat: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  cat: state.cat,
});

export default connect(mapStateToProps, { getProfiles, getAllCats })(Profiles);
