import React, { Fragment } from 'react';
// import spinner from './spinner.gif';
import styles from './Spinner.module.css';

const Spinner = () => (
  <Fragment>
    {/* <img
      src={spinner}
      style={{ width: '200px', margin: 'auto', display: 'block' }}
      alt="Loading..."
    /> */}
    <div
      style={{ width: '60px', margin: 'auto', display: 'block' }}
      className={styles.loader}
    ></div>
  </Fragment>
);

export default Spinner;
