import api from '../utils/api';

import {
  GET_UNREAD_INBOX,
  GET_READ_INBOX,
  INBOX_ERROR,
  READ_ONE_INBOX,
  GET_INBOX_BY_TYPE,
  READ_ALL_INBOX,
} from './types';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get current users profile
export const getUnreadNotification =
  (pageNum = 1, pageSize = 10) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/notification/unread?pageSize=${pageSize}&pageNum=${pageNum}`);

      dispatch({
        type: GET_UNREAD_INBOX,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INBOX_ERROR,
        payload: err,
      });
    }
  };

// Get current users profile
export const getReadNotification =
  (pageNum = 1, pageSize = 10) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/notification/read?pageSize=${pageSize}&pageNum=${pageNum}`);
      console.log('getReadNotification', res.data);
      dispatch({
        type: GET_READ_INBOX,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: INBOX_ERROR,
        payload: err,
      });
    }
  };

// read one notification
export const readOneNotification = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/notification/read/one/${id}`);

    dispatch({
      type: READ_ONE_INBOX,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: INBOX_ERROR,
      payload: err,
    });
  }
};

// read all notification
export const ReadAllNotification =
  (type = 'like') =>
  async (dispatch) => {
    try {
      await api.post(`/notification/read/all?type=${type}`);

      dispatch({
        type: READ_ALL_INBOX,
        payload: type,
      });
      return true;
    } catch (err) {
      dispatch({
        type: INBOX_ERROR,
        payload: err,
      });
    }
  };

// Get current users profile
export const getNotificationByType =
  (pageNum = 1, pageSize = 10, type = 'like') =>
  async (dispatch) => {
    try {
      const res = await api.get(
        `/notification?pageSize=${pageSize}&pageNum=${pageNum}&type=${type}`,
      );
      dispatch({
        type: GET_INBOX_BY_TYPE,
        payload: { type, data: res.data },
      });
    } catch (err) {
      dispatch({
        type: INBOX_ERROR,
        payload: err,
      });
    }
  };
