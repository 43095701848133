// Desc: This file contains all the action types that are used in the application.
// alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// auth
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
// profile
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
// post
export const GET_POSTS = 'GET_POSTS';
export const GET_CONTINUED_POSTS = 'GET_COUNTINUED_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const GET_COMMENTS = 'GET_COMMENTS';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const GET_SUBCOMMENT = 'GET_SUBCOMMENT';
// cat
export const GET_CAT = 'GET_CAT';
export const GET_CATS = 'GET_CATS';
export const GET_ALL_CATS = 'GET_ALL_CATS';
export const GET_MYCATS = 'GET_MYCATS';
export const UPDATE_CAT = 'UPDATE_CAT';
export const CAT_ERROR = 'CAT_ERROR';
export const CLEAR_CAT = 'CLEAR_CAT';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_CAT_AVATAR = 'UPDATE_CAT_AVATAR';
// comment
export const UPDATE_COMMENT_LIKES = 'UPDATE_COMMENT_LIKES';
export const ADD_SUB_COMMENT = 'ADD_SUB_COMMENT';
export const RESET_POSTS = 'RESET_POSTS';
export const VISIBLE_POST = 'VISIBLE_POST';
// inbox
export const GET_UNREAD_INBOX = 'GET_UNREAD_INBOX';
export const GET_READ_INBOX = 'GET_READ_INBOX';
export const GET_INBOX_BY_TYPE = 'GET_INBOX_BY_TYPE';
export const INBOX_ERROR = 'INBOX_ERROR';
export const READ_ONE_INBOX = 'READ_ONE_INBOX';
export const READ_ALL_INBOX = 'READ_ALL_INBOX';
// follow
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const GET_FOLLOWINGS = 'GET_FOLLOWINGS';
export const GET_MY_FOLLOWERS = 'GET_MY_FOLLOWERS';
export const GET_MY_FOLLOWINGS = 'GET_MY_FOLLOWINGS';
export const UPDATE_FOLLOWERS = 'UPDATE_FOLLOWERS';
export const ADD_FOLLOWINGS = 'ADD_FOLLOWINGS';
export const REMOVE_FOLLOWINGS = 'REMOVE_FOLLOWINGS';
export const FOLLOW_ERROR = 'FOLLOW_ERROR';
//chatbot
export const GET_CHAT = 'GET_CHAT';
export const GET_CHATS = 'GET_CHATS';
export const GET_CHAT_MESSAGES = 'GET_CHAT_MESSAGES';
export const GET_CHAT_MESSAGE = 'GET_CHAT_MESSAGE';
export const CHAT_ERROR = 'CHAT_ERROR';
export const CLEAR_CHAT = 'CLEAR_CHAT';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const DELETE_CHAT = 'DELETE_CHAT';
export const ADD_CHAT = 'ADD_CHAT';
export const ACTIVATE_CHAT = 'ACTIVATE_CHAT';
export const GET_ACTIVATION_CODES = 'GET_ACTIVATION_CODES';
// jsonl
export const GET_JSONL = 'GET_JSONL';
export const GET_JSONL_MESSAGES = 'GET_JSONL_MESSAGES';
export const CREATE_JSONL_MESSAGES = 'CREATE_JSONL_MESSAGES';
export const DELETE_JSONL_MESSAGES = 'DELETE_JSONL_MESSAGES';
export const UPDATE_JSONL_MESSAGES = 'UPDATE_JSONL_MESSAGES';
export const CREATE_JSONL_MESSAGE = 'CREATE_JSONL_MESSAGE';
export const UPDATE_JSONL_MESSAGE = 'UPDATE_JSONL_MESSAGE';
export const DELETE_JSONL_MESSAGE = 'DELETE_JSONL_MESSAGE';
export const CLEAR_JSONL = 'CLEAR_JSONL';
