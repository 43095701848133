import React from 'react';
import { Modal, Button } from 'antd';
import PostForm from './PostForm';
import VideoPostForm from './VideoPostForm';
import styles from './CreatePostModal.module.css'; // ensure the path is correct

// Import images - this assumes the images are in the src directory or somewhere accessible by your build tool
import postImage from './img/post.webp'; // update path
import videoImage from './img/video.webp'; // update path

const CreatePostModal = ({ visible, onClose }) => {
  const handleOk = () => {
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title="Create New Post"
      open={visible}
      onClose={onClose}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel} className={styles.footerButton}>
          Cancel
        </Button>,
      ]}
    >
      <div className={styles.modalContent}>
        {/* Button for PostForm */}
        <PostForm
          className={styles.modalButton}
          type="primary"
          onClose={() => {
            handleOk();
          }}
        ></PostForm>
        <img src={postImage} alt="Post" className={styles.imageIcon} />
        {/* Button for VideoPostForm */}
        <VideoPostForm
          className={styles.modalButton}
          type="primary"
          onClose={() => {
            handleOk();
          }}
        ></VideoPostForm>
        <img src={videoImage} alt="Video" className={styles.imageIcon} />
      </div>
    </Modal>
  );
};

export default CreatePostModal;
