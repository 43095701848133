// MobileNavbar.js
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faUserFriends,
  faPlusCircle,
  faEnvelope,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import CreatePostModal from '../posts/CreatePostModal';

// Styled components for the mobile navbar
const MobileNav = styled.nav`
  display: none; // Hidden by default
  @media (max-width: 768px) {
    // Show only on mobile screens
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10000;
  }
`;

const ripple = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  20% {
    transform: scale(3);
    opacity: 0.375;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
`;

const NavItem = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: #333;
  position: relative;
  &.active {
    color: #e91e63; // Active color
    // Add this before pseudo-element for the ripple effect
    &::before {
      content: '';
      position: absolute;
      left: 30%;
      top: 30%;
      width: 20px;
      height: 20px;
      background-color: #e91e63; // Ripple color
      border-radius: 50%;
      animation: ${ripple} 0.4s ease-in-out forwards;
      z-index: 0;
    }
  }
  & svg {
    margin-bottom: 5px;
    position: relative;
    z-index: 1;
  }
`;

const NavLabel = styled.span`
  font-size: 0.75rem;
`;
// Style for the notification bubble
const NotificationBubble = styled.span`
  position: absolute;
  top: 0px; // Adjust this value as needed
  right: -2px; // Adjust this value as needed
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px; // Ensure it's round even if there's only one digit
  height: 18px; // Make it a perfect circle
`;

// Create a function to determine if the path is active
const isActivePath = (path, location) => {
  return location.pathname === path;
};

const MobileNavbar = ({
  auth: { user, isAuthenticated },
  logout,
  notification: {
    totalUnreadLikeNotifications,
    totalUnreadCommentNotifications,
    totalUnreadFollowNotifications,
    totalUnreads,
  },
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = React.useState(false);
  let totalUnreadNotifications =
    totalUnreadCommentNotifications + totalUnreadLikeNotifications + totalUnreadFollowNotifications;
  return (
    <>
      <MobileNav>
        <NavItem
          to="/"
          className={
            isActivePath('/', location) || isActivePath('/posts', location) ? 'active' : ''
          }
        >
          <FontAwesomeIcon icon={faHome} size="lg" />
          <NavLabel>Home</NavLabel>
        </NavItem>
        <NavItem
          to={isAuthenticated ? '/posts-following' : '/login'}
          className={isActivePath('/posts-following', location) ? 'active' : ''}
        >
          <FontAwesomeIcon icon={faUserFriends} size="lg" />
          <NavLabel>Follow</NavLabel>
        </NavItem>
        <NavItem
          to={!isAuthenticated && '/login'}
          onClick={() => {
            if (!isAuthenticated) {
              // console.log('Please login to create a post');
              navigate('/login');
              return;
            }
            setModalVisible(true);
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          <NavLabel>Create</NavLabel>
        </NavItem>
        <NavItem
          to={isAuthenticated ? '/inbox' : '/login'}
          className={isActivePath('/inbox', location) ? 'active' : ''}
        >
          <FontAwesomeIcon icon={faEnvelope} size="lg" />
          <NavLabel>Inbox</NavLabel>
          {isAuthenticated && totalUnreadNotifications > 0 && (
            <NotificationBubble>
              {totalUnreadNotifications > 99 ? '99+' : totalUnreadNotifications}
            </NotificationBubble>
          )}
        </NavItem>
        <NavItem
          to={isAuthenticated ? `/profile/${user?._id}` : '/login'}
          className={isActivePath(`/profile/${user?._id}`, location) ? 'active' : ''}
        >
          <FontAwesomeIcon icon={faUser} size="lg" />
          <NavLabel>Me</NavLabel>
        </NavItem>
      </MobileNav>
      <CreatePostModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </>
  );
};

MobileNavbar.prototype = {
  auth: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  notification: state.notification,
});

export default connect(mapStateToProps, {})(MobileNavbar);
