import api from '../utils/api';
import { setAlert } from './alert';

import {
  GET_FOLLOWERS,
  GET_FOLLOWINGS,
  FOLLOW_ERROR,
  GET_MY_FOLLOWERS,
  GET_MY_FOLLOWINGS,
  UPDATE_FOLLOWERS,
  ADD_FOLLOWINGS,
  REMOVE_FOLLOWINGS,
  GET_POSTS,
} from './types';

export const addFollowing = (userId) => async (dispatch) => {
  try {
    const res = await api.post(`/follow/follow/${userId}`);

    dispatch({
      type: ADD_FOLLOWINGS,
      payload: res.data,
    });
    return true;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }

    return false;
  }
};

export const removeFollowing = (userId) => async (dispatch) => {
  try {
    await api.post(`/follow/unfollow/${userId}`);
    dispatch({
      type: REMOVE_FOLLOWINGS,
      payload: userId,
    });
    return true;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }

    return false;
  }
};

export const getMyFollowings =
  (pageNum = 1, pageSize = 10, name = '') =>
  async (dispatch) => {
    try {
      const res = await api.get(
        `/follow/following/me?pageNum=${pageNum}&pageSize=${pageSize}&name=${name}`,
      );
      dispatch({
        type: GET_MY_FOLLOWINGS,
        payload: res.data,
      });
      return true;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        dispatch(setAlert(err.response.data.msg, 'danger'));
      }
      if (err.response) {
        dispatch({
          type: FOLLOW_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status },
        });
      }

      return false;
    }
  };

export const getMyFollowers =
  (pageNum = 1, pageSize = 10, name = '') =>
  async (dispatch) => {
    try {
      const res = await api.get(
        `/follow/follower/me?pageNum=${pageNum}&pageSize=${pageSize}&name=${name}`,
      );

      dispatch({
        type: GET_MY_FOLLOWERS,
        payload: res.data,
      });
      return true;
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        dispatch(setAlert(err.response.data.msg, 'danger'));
      }
      if (err.response) {
        dispatch({
          type: FOLLOW_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status },
        });
      }
      return false;
    }
  };

// Create cat
export const getIsFollowing = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/follow/isFollowing/${userId}`);

    return res.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
    return false;
  }
};

export const getOneFollowings = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/follow/following/one/${userId}`);

    dispatch({
      type: GET_FOLLOWINGS,
      payload: res.data,
    });
    return true;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }

    return false;
  }
};

export const getOneFollowers = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/follow/followers/one/${userId}`);
    dispatch({
      type: GET_FOLLOWERS,
      payload: res.data,
    });
    return true;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
    return false;
  }
};

export const getFollowingsNumber = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/follow/following/number/${userId}`);
    return res.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }

    return false;
  }
};

export const getFollowersNumber = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/follow/follower/number/${userId}`);
    return res.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
    return false;
  }
};

export const removeFollower = (followId) => async (dispatch) => {
  try {
    await api.post(`/follower/remove/${followId}`);

    dispatch({
      type: UPDATE_FOLLOWERS,
      payload: followId,
    });
    return true;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }

    return false;
  }
};
export const getIsFriend = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/follow/isFriend/${userId}`);

    return res.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
    return false;
  }
};

export const getPostsFollowing = (pageNum, pageSize, search) => async (dispatch) => {
  try {
    const res = await api.get(
      `/follow/posts?pageNum=${pageNum}&pageSize=${pageSize}&search=${search}`,
    );
    dispatch({
      type: GET_POSTS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    if (err.response) {
      dispatch({
        type: FOLLOW_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status },
      });
    }
    return false;
  }
};
