import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addPostVideo } from '../../actions/post';
import { Button, Modal, message, Progress, Checkbox } from 'antd';
import { getMyCats } from '../../actions/cat';
import Spinner from '../layout/Spinner';
import extractFrame from '../../utils/extractFrame';
import useFFmpegTranscoder from '../../utils/useFFmpegTranscoder';
import './Posts.css';

const PostForm = ({ addPostVideo, getMyCats, cat: { mycats }, onClose }) => {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isUploadVideo, setIsUploadVideo] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [isConverting, setConverting] = useState(false);
  const [cat, setCat] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isFullQuality, setIsFullQuality] = useState(false);
  const [isFullQualityDisabled, setIsFullQualityDisabled] = useState(false);
  const { transcodeVideo, isFFmpegLoaded, messageLoading } = useFFmpegTranscoder();

  useEffect(() => {
    getMyCats();
  }, [getMyCats]);

  const [coverImage, setCoverImage] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    if (isPreviewing) {
      setIsPreviewing(false);
      setIsUploadVideo(false);
      setIsFullQualityDisabled(true);
      return;
    }
    if (isConverting) {
      messageApi.open({
        type: 'error',
        content: 'Please wait for video converting',
      });
      return;
    }
    if (!title || !text || !videoFile) {
      messageApi.open({
        type: 'error',
        content: 'Please fill all fields',
      });
      return;
    }
    setUploading(true);
    const created = await handleSubmit();
    if (!created) {
      messageApi.open({
        type: 'error',
        content: 'Post uploaded failed',
      });
      setUploading(false);
      return;
    }
    setIsModalOpen(false);
    setText('');
    setTitle('');
    setVideoFile(null);
    setVideoPreviewUrl(null);
    setUploading(false);
    messageApi.open({
      type: 'success',
      content: 'Post uploaded',
    });
    onClose();
  };

  const handleCancel = () => {
    setUploading(false);
    setIsModalOpen(false);
  };

  const handleFileSelect = (e) => {
    const fileInput = e.target;
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('video/')) {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = async () => {
        window.URL.revokeObjectURL(video.src);
        const duration = video.duration;
        const fileSize = selectedFile.size;
        const maxSize = 30 * 1024 * 1024;
        if ((duration > 15 && fileSize > maxSize) || duration < 2) {
          messageApi.open({
            type: 'error',
            content: 'Video is too long. Maximum length is 10 seconds.',
          });
          setVideoFile(null); // Clear the selected file
          setVideoPreviewUrl(null);
          fileInput.value = '';
        } else {
          const frameBlob = await extractFrame(selectedFile); // Adjust the frameNumber and fps as needed
          setCoverImage(frameBlob);
          // You can also set a preview URL to show the extracted frame to the user
          setCoverImageUrl(URL.createObjectURL(frameBlob));
          if (isFFmpegLoaded && !isFullQuality && selectedFile.type !== 'video/mp4') {
            setConverting(true);
            const transcodedFile = await transcodeVideo(selectedFile);
            setVideoFile(transcodedFile);
            setVideoPreviewUrl(URL.createObjectURL(transcodedFile));
            setConverting(false);
            setIsPreviewing(true);
          } else {
            setVideoFile(selectedFile);
            setVideoPreviewUrl(URL.createObjectURL(selectedFile));
            setIsPreviewing(true);
          }
        }
      };
      video.src = URL.createObjectURL(selectedFile);
    } else {
      messageApi.open({
        type: 'error',
        content: 'Please select a video file',
      });
      setVideoFile(null); // Clear the selected file
      setVideoPreviewUrl(null); // Clear the preview URL
    }
  };

  const handleOnClickVideoButton = () => {
    setIsUploadVideo(true);
    setVideoFile(null);
    setVideoPreviewUrl(null);
    setCoverImage(null);
    setCoverImageUrl(null);
    setIsFullQualityDisabled(false);
  };
  const onSelectCat = (e) => {
    setCat(e.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    if (!videoFile) {
      messageApi.open({
        type: 'error',
        content: 'Please select a video file',
      });
      return false;
    }
    if (coverImage) {
      formData.append('cover', coverImage, 'cover.jpg');
    } else {
      try {
        const coverBlob = await extractFrame(videoFile);
        formData.append('cover', coverBlob, 'cover.jpg');
      } catch (error) {
        console.error('Error extracting cover image:', error);
        messageApi.open({
          type: 'error',
          content: 'Failed to extract cover image',
        });
        return false;
      }
    }
    formData.append('video', videoFile); // Append video file to FormData
    formData.append('title', title);
    formData.append('text', text);
    formData.append('cat', cat);
    formData.append('type', 'video');
    // ... other formData appends ...
    try {
      const created = await addPostVideo(formData);
      return created;
    } catch (error) {
      console.error('Error uploading post:', error);
      return false;
    }
  };

  return (
    <Fragment>
      {contextHolder}
      <div>
        <Button
          className="btn-white"
          shape="square"
          size="large"
          icon={<i className="fa-solid fa-plus"></i>}
          onClick={showModal}
        >
          Create A Video Post
        </Button>
      </div>
      <Modal
        title={isConverting ? 'Converting video, do not leave the page...' : 'Create a post'}
        okText={isPreviewing ? 'Continue' : 'Submit'}
        okType={isPreviewing ? 'primary' : 'danger'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isConverting }}
        cancelButtonProps={{ disabled: isConverting }}
      >
        {uploading ? (
          <Spinner />
        ) : (
          <div className="post-form">
            {isConverting ? (
              <div className="post-form-preview-loading-container">
                <Progress type="circle" percent={Math.floor(messageLoading)} />
              </div>
            ) : (
              <>
                {isPreviewing ? (
                  <>
                    <div className="post-form-preview-loading-container">
                      <video
                        className="video-preview video-js"
                        playsInline
                        preload="auto"
                        autoPlay
                        controls
                        data-setup="{}"
                      >
                        <source src={videoPreviewUrl} type="video/mp4" />
                      </video>
                    </div>
                    {/* This is the new button */}
                  </>
                ) : (
                  <Fragment>
                    <form className="form my-1">
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Title* (required)"
                          name="title"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                        <small className="form-text">Title of your post</small>
                      </div>
                      {mycats && (
                        <div className="form-group">
                          <select name="status" value={cat} onChange={onSelectCat}>
                            <option>* Select Your Cat for the post</option>
                            {mycats.map((cat) => (
                              <option key={cat._id} value={cat._id}>
                                {cat.name}
                              </option>
                            ))}
                          </select>
                          <small className="form-text">Your cat</small>
                        </div>
                      )}
                      <textarea
                        name="text"
                        cols="30"
                        rows="5"
                        placeholder="Create a post (required)"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        required
                      />
                      <small className="form-text">*text is required</small>
                      <div className="icon-options">
                        <div
                          className="icon-box item-option"
                          onClick={() => {
                            handleOnClickVideoButton();
                          }}
                        >
                          <i className="fa-solid fa-video" style={{ color: '#236995' }}></i>
                        </div>
                        <Checkbox
                          disabled={isFullQualityDisabled}
                          checked={isFullQuality}
                          onChange={(e) => setIsFullQuality(e.target.checked)}
                        >
                          {' '}
                          <p style={{ color: 'gray', fontSize: '0.7rem' }}>Full Quality</p>
                        </Checkbox>
                      </div>

                      <small className="form-text">
                        *Video is required. Please ensure video length is less than 15s/20mb.
                        <i className="fa-solid fa-cat" style={{ color: '#1a8973' }} />
                      </small>
                      {isUploadVideo && (
                        <input
                          type="file"
                          name="video"
                          accept="video/mp4, .mp4, .mov"
                          onChange={handleFileSelect}
                        />
                      )}
                      {isConverting && <p>{messageLoading}</p>}
                      <div className="pictures form-group">
                        {videoPreviewUrl && (
                          <>
                            {coverImageUrl ? (
                              <img
                                className="video-preview-small"
                                onClick={() => {
                                  setIsPreviewing(true);
                                }}
                                src={coverImageUrl}
                                alt=""
                              />
                            ) : (
                              <video
                                playsInline
                                className="video-preview-small video-js"
                                preload="auto"
                                autoPlay
                                controls
                                data-setup="{}"
                              >
                                <source src={videoPreviewUrl} type="video/mp4" />
                              </video>
                            )}

                            <div
                              className="icon-box item-option"
                              onClick={() => {
                                setIsPreviewing(true);
                              }}
                            >
                              <i className="fa-solid fa-eye"></i>
                            </div>
                          </>
                        )}
                      </div>
                    </form>
                  </Fragment>
                )}
              </>
            )}
          </div>
        )}
      </Modal>
    </Fragment>
  );
};

PostForm.propTypes = {
  addPostVideo: PropTypes.func.isRequired,
  getMyCats: PropTypes.func.isRequired,
  cat: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  cat: state.cat,
});

export default connect(mapStateToProps, { addPostVideo, getMyCats })(PostForm);
