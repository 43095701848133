import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FollowButton from '../../general/follow-buttun/FollowButton';
import './FollowItem.css'; // make sure you have the correct path to your CSS file

const NotificationItem = ({
  follow: { follower, following, followerProfile, followingProfile },
  isFollower,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [followUser, setFollowerUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // When the component mounts, set the fade in effect
    setFadeIn(true);
    if (isFollower) {
      setFollowerUser(follower);
      setProfile(followerProfile);
    } else {
      setFollowerUser(following);
      setProfile(followingProfile);
    }
  }, [isFollower, follower, following, followerProfile, followingProfile]);

  const handleOnClickNameAvatar = () => {
    navigate(`/profile/${followUser._id}`);
  };
  // This is the function that will be called when a comment follow-list is clicked

  return (
    <div className={`follow-list-item unread ${fadeIn ? 'fadeInTopToBottom' : ''}`}>
      <div className="follow-list-avatar" onClick={handleOnClickNameAvatar}>
        {followUser && followUser.avatar && (
          <img
            src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + followUser.avatar}
            alt={followUser.username}
          />
        )}
      </div>
      <div className="follow-list-info">
        <div className="follow-list-text">
          <strong
            className="follow-list-name"
            style={{ cursor: 'pointer' }}
            onClick={handleOnClickNameAvatar}
          >
            {profile && profile.name}
          </strong>
        </div>
      </div>
      <div className="follow-list-follow-button">
        {followUser && followUser._id && <FollowButton user={followUser._id} />}
      </div>
    </div>
  );
};

NotificationItem.propTypes = {
  follow: PropTypes.object.isRequired,
  isFollower: PropTypes.bool.isRequired,
};

export default connect(null, {})(NotificationItem);
