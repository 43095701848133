import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FollowButton from '../general/follow-buttun/FollowButton';
import { getFollowersNumber, getFollowingsNumber } from '../../actions/follow';
import { getUserPostsNumberById } from '../../actions/post';
import './Profile.css';

const ProfileTop = ({
  auth,
  profile: {
    name,
    website,
    status,
    location,
    social,
    user: { _id, avatar },
  },
  getFollowersNumber,
  getFollowingsNumber,
  getUserPostsNumberById,
}) => {
  const navigate = useNavigate();
  const [followersNumber, setFollowersNumber] = useState(0);
  const [followingsNumber, setFollowingsNumber] = useState(0);
  const [postsNumber, setPostsNumber] = useState(0);
  return (
    useEffect(() => {
      const fetchFollow = async () => {
        if (auth && !auth.loading && _id) {
          let followersNumber = await getFollowersNumber(_id);
          let followingsNumber = await getFollowingsNumber(_id);
          let postsNumber = await getUserPostsNumberById(_id);
          setFollowersNumber(followersNumber);
          setFollowingsNumber(followingsNumber);
          setPostsNumber(postsNumber);
        }
      };
      fetchFollow();
    }, [getFollowersNumber, getFollowingsNumber, getUserPostsNumberById, auth, _id]),
    (
      <div className="profile-top bg-primary p-1">
        <div className="profile-top-profile-button-container">
          {auth.isAuthenticated && auth.loading === false && auth.user._id === _id ? (
            <Link to="/dashboard" className="">
              <div className="rounded-button light">
                <i className="fa-solid fa-pen-to-square"></i> <span className="">Edit</span>
              </div>
            </Link>
          ) : (
            <div className="rounded-button">
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
          )}
        </div>
        <div className="avatar-container">
          <div className="avatar">
            {avatar ? (
              <img src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + avatar} alt="" />
            ) : (
              <img
                src={
                  process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT +
                  'public/user/avatar/default-avatar.jpeg'
                }
                alt=""
              />
            )}
          </div>
        </div>
        <h1 className="large" style={{ color: 'white' }}>
          {name}
        </h1>

        <p>{location ? <span style={{ color: '#fff' }}>{location}</span> : null}</p>
        <div className="icons my-1">
          {website ? (
            <a href={website} target="_blank" rel="noopener noreferrer">
              <i className="fas fa-globe fa-2x" />
            </a>
          ) : null}
          {social
            ? Object.entries(social)
                .filter(([_, value]) => value)
                .map(([key, value]) => (
                  <a key={key} href={value} target="_blank" rel="noopener noreferrer">
                    <i className={`fab fa-${key} fa-2x`}></i>
                  </a>
                ))
            : null}
        </div>
        {auth.isAuthenticated && auth.loading === false && auth.user._id !== _id && (
          <div className="profile-follow-button-container ">
            <FollowButton user={_id} />
          </div>
        )}

        <div className="profile-follow-stats-container">
          <div className="profile-follow-stats-item">
            <span className="profile-follow-stats-count">{postsNumber}</span>
            <span className="profile-follow-stats-name">Posts</span>
          </div>
          <div
            className="profile-follow-stats-item"
            onClick={() => {
              if (auth.isAuthenticated && auth.loading === false && auth.user._id === _id) {
                navigate(`/follow?isFollowerslist=${true}`);
              }
            }}
          >
            <span className="profile-follow-stats-count">{followersNumber}</span>
            <span className="profile-follow-stats-name">Followers</span>
          </div>
          <div
            className="profile-follow-stats-item"
            onClick={() => {
              if (auth.isAuthenticated && auth.loading === false && auth.user._id === _id) {
                navigate(`/follow?isFollowerslist=${false}`);
              }
            }}
          >
            <span className="profile-follow-stats-count">{followingsNumber}</span>
            <span className="profile-follow-stats-name">Following</span>
          </div>
        </div>
      </div>
    )
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getFollowersNumber: PropTypes.func.isRequired,
  getFollowingsNumber: PropTypes.func.isRequired,
  getUserPostsNumberById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getFollowersNumber,
  getFollowingsNumber,
  getUserPostsNumberById,
})(ProfileTop);
