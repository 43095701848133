import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';
import cat from './cat';
import notification from './notification';
import follow from './follow';
import chatbot from './chatbot';
import jsonl from './jsonl';

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  cat,
  notification,
  follow,
  chatbot,
  jsonl,
});
