import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import PostItem from '../post/PostItem';
import CommentForm from '../post/CommentForm';
import CommentItem from '../post/CommentItem';
import { getPost, getPostComments, getComments } from '../../actions/post';
import { FloatButton, Pagination } from 'antd';

const Post = ({ getPost, getPostComments, getComments, post: { post, loading } }) => {
  const { id } = useParams();
  useEffect(() => {
    getPostComments(id);
  }, [id, getPostComments]);
  const handlePageChange = async (page, pageSize) => {
    // setProfilePage(page);
    // setProfilePageSize(pageSize);
    if (post) {
      await getComments(post._id, page, pageSize);
    }
  };
  return loading || post === null || post.comments === null ? (
    <section className="container">
      <Spinner />
    </section>
  ) : (
    <section className="container">
      <Link to="/posts" className="btn">
        Back To Posts
      </Link>
      <PostItem post={post} showActions={false} />
      <CommentForm postId={post._id} />
      <div className="comments">
        {post.comments &&
          post.comments.length > 0 &&
          post.comments.map((comment) => (
            <CommentItem
              key={comment._id}
              id={`comment-${comment._id}`}
              comment={comment}
              postId={post._id}
            />
          ))}
      </div>
      <div className="m-2">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          pageSizeOptions={[1, 5, 10, 20]}
          onChange={handlePageChange}
          total={post.comments.totalComments}
        />
      </div>

      <FloatButton.BackTop />
    </section>
  );
};

Post.propTypes = {
  getPost: PropTypes.func.isRequired,
  getPostComments: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPost, getPostComments, getComments })(Post);
