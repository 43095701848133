import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getJsonl, deleteJsonlMessages } from '../../actions/jsonl';
import styles from './GenerateJsonl.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faDownload } from '@fortawesome/free-solid-svg-icons';

const GenerateJsonl = ({ auth, jsonl: { messagesList }, getJsonl, deleteJsonlMessages }) => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  const handleDelete = (chatId) => {
    if (window.confirm('Are you sure you want to delete this chatbot?')) {
      deleteJsonlMessages(chatId);
    }
  };

  const handleFileChange = (event) => {
    handleFileUpload(event.target.files[0]);
  };

  const handleFileUpload = async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const messages = text.split('\n').filter(Boolean).map(JSON.parse);
        getJsonl(messages);
      };
      reader.readAsText(file);
    }
  };
  const handleExportJsonl = () => {
    // Convert each message in the list to a JSON string followed by a newline character
    const messagesListWithOutId = messagesList.map((message) => {
      const { _id, ...rest } = message;
      return rest;
    });
    const jsonlString = messagesListWithOutId.map((message) => JSON.stringify(message)).join('\n');
    const blob = new Blob([jsonlString], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'messages.jsonl'; // Specify the file name for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url); // Clean up by revoking the object URL after use
  };

  const handleAddChat = async () => {
    navigate('/admin/generate-jsonl-chat/0');
  };

  return (
    <div className="container">
      <button onClick={handleAddChat} className={styles.addButton}>
        <FontAwesomeIcon icon={faPlus} /> Create a new Chat For Training
      </button>
      <button onClick={handleExportJsonl} className={styles.addButton}>
        <FontAwesomeIcon icon={faDownload} /> Export as JSONL
      </button>
      <input type="file" id="file" className={styles.fileInput} onChange={handleFileChange} />
      <label htmlFor="file" className={styles.fileInputLabel}>
        Choose json File for Training
      </label>
      <ul className={styles.chatList}>
        {messagesList.map((chat, index) => (
          <li key={index} className={styles.chatItem}>
            <span
              className={styles.chatTitle}
              onClick={() => navigate(`/admin/generate-jsonl-chat/${chat._id}`)}
            >{`Chat #${chat._id}`}</span>
            <button onClick={() => handleDelete(chat._id)} className={styles.trashButton}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

GenerateJsonl.propTypes = {
  auth: PropTypes.object.isRequired,
  jsonl: PropTypes.object.isRequired,
  getJsonl: PropTypes.func.isRequired,
  deleteJsonlMessages: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  jsonl: state.jsonl,
});

export default connect(mapStateToProps, { getJsonl, deleteJsonlMessages })(GenerateJsonl);
