import {
  GET_JSONL,
  GET_JSONL_MESSAGES,
  CREATE_JSONL_MESSAGES,
  DELETE_JSONL_MESSAGES,
  UPDATE_JSONL_MESSAGES,
  CLEAR_JSONL,
} from '../actions/types';

import { v4 as uuidv4 } from 'uuid';

const initialState = {
  messagesList: [],
  chats: {},
};

function jsonlReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_JSONL:
      return {
        ...state,
        messagesList: payload,
      };
    case GET_JSONL_MESSAGES:
      return {
        ...state,
        chats: state.messagesList.find((message) => message._id === payload),
      };
    case CREATE_JSONL_MESSAGES:
      return {
        ...state,
        messagesList: [...state.messagesList, { messages: payload, _id: uuidv4() }],
      };
    case DELETE_JSONL_MESSAGES:
      return {
        ...state,
        messagesList: state.messagesList.filter((message) => message._id !== payload),
      };
    case UPDATE_JSONL_MESSAGES:
      return {
        ...state,
        messagesList: state.messagesList.map((message) =>
          message._id === payload._id ? payload : message,
        ),
      };
    case CLEAR_JSONL:
      return {
        ...state,
        messagesList: [],
        chats: {},
      };
    default:
      return state;
  }
}

export default jsonlReducer;
