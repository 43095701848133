import api from '../utils/api';
import { setAlert } from './alert';
import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_LIKES,
  DELETE_POST,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  COMMENT_ERROR,
  GET_COMMENTS,
  UPDATE_COMMENT_LIKES,
  VISIBLE_POST,
  GET_CONTINUED_POSTS,
  GET_SUBCOMMENT,
} from './types';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get posts
export const getPosts =
  (pageNum = 1, pageSize = 12, title) =>
  async (dispatch) => {
    try {
      const res = await api.get('/posts', {
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          title: title,
        },
      });
      dispatch({
        type: GET_POSTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: POST_ERROR,
        payload: err,
      });
    }
  };

export const loadMorePosts =
  (pageNum = 1, pageSize = 12, title = '') =>
  async (dispatch) => {
    try {
      // console.log('loadMorePosts', pageNum, pageSize, title);
      let res;
      if (title === '') {
        res = await api.get('/posts', {
          params: {
            pageNum: pageNum,
            pageSize: pageSize,
            title: title,
          },
        });
      } else {
        res = await api.get('/posts/embedding/search', {
          params: {
            pageNum: pageNum,
            pageSize: pageSize,
            title: title,
          },
        });
      }
      dispatch({
        type: GET_CONTINUED_POSTS,
        payload: { data: res.data, pageNum: pageNum, pageSize: pageSize, title: title },
      });
    } catch (err) {
      dispatch({
        type: POST_ERROR,
        payload: err,
      });
    }
  };

// Get posts
export const getPostsGuest =
  (pageNum = 1, pageSize = 12, title) =>
  async (dispatch) => {
    try {
      const res = await api.get('/posts/guest', {
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          title: title,
        },
      });

      dispatch({
        type: GET_POSTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: POST_ERROR,
        payload: err,
      });
    }
  };

export const loadMorePostsGuest =
  (pageNum = 1, pageSize = 12, title = '') =>
  async (dispatch) => {
    try {
      let res;
      if (title === '') {
        res = await api.get('/posts/guest', {
          params: {
            pageNum: pageNum,
            pageSize: pageSize,
            title: title,
          },
        });
      } else {
        res = await api.get('/posts/embedding/search', {
          params: {
            pageNum: pageNum,
            pageSize: pageSize,
            title: title,
          },
        });
      }
      dispatch({
        type: GET_CONTINUED_POSTS,
        payload: { data: res.data, pageNum: pageNum, pageSize: pageSize, title: title },
      });
    } catch (err) {
      dispatch({
        type: POST_ERROR,
        payload: err,
      });
    }
  };
// Get user posts
export const getUserPostsById =
  (pageNum = 1, pageSize = 4, title = '', userId) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/posts/user/${userId}`, {
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          title: title,
        },
      });
      dispatch({
        type: GET_POSTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: POST_ERROR,
        payload: err,
      });
    }
  };

// Add like
export const addLike = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/posts/like/${id}`);
    dispatch({
      type: UPDATE_LIKES,
      payload: { id, updateLikes: res.data, isLiked: true },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    console.log(err);
    const msg = err?.response?.data?.msg;
    if (msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/posts/unlike/${id}`);
    dispatch({
      type: UPDATE_LIKES,
      payload: { id, updateLikes: res.data, isLiked: false },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    console.log(err);
    const msg = err?.response?.data?.msg;
    if (msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

// Delete post
export const deletePost = (id) => async (dispatch) => {
  try {
    await api.delete(`/posts/${id}`);

    dispatch({
      type: DELETE_POST,
      payload: id,
    });

    dispatch(setAlert('Post Removed', 'success'));
    return true;
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    const msg = err?.response?.data?.msg;
    if (msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

// Add post
export const addPost = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/posts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: ADD_POST,
      payload: res.data,
    });

    dispatch(setAlert('Post Created', 'success'));
    return true;
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

// Add post with video
export const addPostVideo = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/posts/video', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: ADD_POST,
      payload: res.data,
    });

    dispatch(setAlert('Post Created', 'success'));
    return true;
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

// Get post
export const getPost = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/posts/${id}`);

    dispatch({
      type: GET_POST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

// Add comment
export const addComment =
  (postId, formData, rootId = '') =>
  async (dispatch) => {
    try {
      // const res = await api.post(`/posts/comment/${postId}?root=${rootId}`, formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // });
      const res = await api.post(`/comments/post-comment/${postId}?root=${rootId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch({
        type: ADD_COMMENT,
        payload: res.data,
      });

      dispatch(setAlert('Comment Added', 'success'));
      return true;
    } catch (err) {
      dispatch({
        type: POST_ERROR,
        payload: err,
      });
      return false;
    }
  };

// Delete comment
export const deleteComment = (postId, commentId, rootId) => async (dispatch) => {
  try {
    // const res = await api.delete(`/posts/comment/${postId}/${commentId}?root=${rootId}`);
    const res = await api.delete(`/comments/delete-comment/${commentId}?root=${rootId}`);
    dispatch({
      type: REMOVE_COMMENT,
      payload: {
        commentId, // Include commentId in the payload
        data: res.data, // Include res.data in the payload under a different key
      },
    });

    dispatch(setAlert('Comment Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

// Get post & Comment
export const getPostComments = (id) => async (dispatch) => {
  try {
    // const resComment = await api.get(`/posts/comment/${id}`);
    // const resPost = await api.get(`/posts/${id}`);
    const [resComment, resPost] = await Promise.all([
      api.get(`/comments/comments-in-posts/${id}`),
      api.get(`/posts/${id}`),
    ]);
    dispatch({
      type: GET_POST,
      payload: resPost.data,
    });
    dispatch({
      type: GET_COMMENTS,
      payload: resComment.data,
    });
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

// Get post & Comment
export const getComments = (id, pageNum, pageSize) => async (dispatch) => {
  try {
    const resComment = await api.get(
      `/comments/comments-in-posts/${id}?pageNum=${pageNum}&pageSize=${pageSize}`,
    );

    dispatch({
      type: GET_COMMENTS,
      payload: resComment.data,
    });
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

export const getSubcommentsByCommentId = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/comments/subcomments-in-comment/${id}`);
    dispatch({
      type: GET_SUBCOMMENT,
      payload: {
        commentId: id,
        subcomments: res.data,
      },
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: err,
    });
    dispatch(setAlert(err?.response?.data?.msg, 'danger'));
  }
};

// Get post & Comment
export const getOneComments = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/posts/comment/single/${id}`);

    dispatch({
      type: ADD_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMMENT_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

// Add like
export const addCommentLike = (commentId, rootId) => async (dispatch) => {
  try {
    const res = await api.post(`/likes/comment/${commentId}`);

    dispatch({
      type: UPDATE_COMMENT_LIKES,
      payload: {
        commentId,
        rootId,
        isLiked: true,
        likes: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

// Remove like
export const removeCommentLike = (commentId, rootId) => async (dispatch) => {
  try {
    const res = await api.delete(`/likes/comment/${commentId}`);

    dispatch({
      type: UPDATE_COMMENT_LIKES,
      payload: {
        commentId,
        rootId,
        isLiked: false,
        likes: res.data,
      },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });

    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
};

export const changeVisiblePost = (postId) => async (dispatch) => {
  try {
    const res = await api.post(`/posts/visible/${postId}`);
    dispatch({
      type: VISIBLE_POST,
      payload: { postId, isVisible: res.data.isVisible },
    });
    return true;
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
    if (err.response && err.response.data && err.response.data.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

// Get user posts
export const getUserPostsNumberById = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/posts/user/number/${userId}`);
    return res.data;
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: err,
    });
  }
};
