import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import FollowItem from './FollowItem';
import { getMyFollowers, getMyFollowings } from '../../../actions/follow';
import { Pagination } from 'antd';

import './Follows.css';
import { useLocation } from 'react-router-dom';

// const { Search } = Input;

const Follows = ({
  auth: { user },
  getUnreadNotification,
  follow: { myFollowers, myFollowings, myTotalFollowings, myTotalFollowers, loading },
  getMyFollowers,
  getMyFollowings,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  let isFollowerslist = query.get('isFollowerslist') || true;
  let searchName = query.get('name') || '';
  let pageQuery = query.get('page') || 1;
  let pageSizeQuery = query.get('pageSize') || 12;
  const [isFollowerslistState, setIsFollowerslistState] = useState(isFollowerslist);
  useEffect(() => {
    if (isFollowerslist === 'true') {
      getMyFollowers(pageQuery, pageSizeQuery, searchName);
      setIsFollowerslistState(true);
    } else {
      getMyFollowings(pageQuery, pageSizeQuery, searchName);
      setIsFollowerslistState(false);
    }
  }, [
    getUnreadNotification,
    isFollowerslist,
    getMyFollowers,
    getMyFollowings,
    searchName,
    pageQuery,
    pageSizeQuery,
  ]);
  // const [searchText, setSearchText] = useState('');
  // const [profilePage, setProfilePage] = useState('');
  // const [profilePageSize, setProfilePageSize] = useState('');

  const handlePageChange = async (page, pageSize) => {
    // setProfilePage(page);
    // setProfilePageSize(pageSize);
    if (isFollowerslist) {
      navigate(
        `/follow?isFollowerslist=${true}&page=${page}&pageSize=${pageSize}&name=${searchName}`,
      );
    } else {
      navigate(
        `/follow?isFollowerslist=${false}&page=${page}&pageSize=${pageSize}&name=${searchName}`,
      );
    }
  };

  // const onSearch = async (value, _e, info) => {
  //   setSearchText(value);
  //   await getProfiles(profilePage, profilePageSize, value);
  // };

  return (
    <section className="container">
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="large text-light">
            <i className="fa-solid fa-user-group"></i>{' '}
            {isFollowerslistState ? 'Followers' : 'Followings'}
          </h1>

          {/* <Search
            className="my-1"
            placeholder="search user name"
            allowClear
            enterButton="Search"
            size="large"
            onSearch={onSearch}
          /> */}
          <div className="follow-user-list-buttons">
            <button
              className={`follow-user-list-btn ${isFollowerslistState ? 'active' : ''}`}
              onClick={() => {
                navigate(`/follow?isFollowerslist=${true}`);
              }}
            >
              Followers <span>{myTotalFollowers}</span>
            </button>
            <button
              className={`notification-btn ${isFollowerslistState ? '' : 'active'}`}
              onClick={() => {
                navigate(`/follow?isFollowerslist=${false}`);
              }}
            >
              Followings <span>{myTotalFollowings}</span>
            </button>
            <div className={`slider ${isFollowerslistState ? 'followers' : 'followings'}`}></div>
          </div>
          <div className="profiles">
            {isFollowerslistState && (
              <>
                {myFollowers && myFollowers.length > 0 ? (
                  myFollowers.map((follow) => (
                    <FollowItem key={follow._id} follow={follow} isFollower={true} />
                  ))
                ) : (
                  <h4>No {isFollowerslist ? 'Followers' : 'Following'}...</h4>
                )}
              </>
            )}
            {!isFollowerslistState && (
              <>
                {myFollowings && myFollowings.length > 0 ? (
                  myFollowings.map((follow) => (
                    <FollowItem key={follow._id} follow={follow} isFollower={false} />
                  ))
                ) : (
                  <h4>No new messages...</h4>
                )}
              </>
            )}
          </div>
          <div className="m-2">
            <Pagination
              showSizeChanger
              current={pageQuery && Number(pageQuery)}
              defaultCurrent={1}
              defaultPageSize={10}
              pageSizeOptions={[1, 5, 10, 20]}
              onChange={handlePageChange}
              total={isFollowerslistState ? myTotalFollowers : myTotalFollowings}
            />
          </div>
        </Fragment>
      )}
    </section>
  );
};

Follows.propTypes = {
  getMyFollowers: PropTypes.func.isRequired,
  getMyFollowings: PropTypes.func.isRequired,
  follow: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  follow: state.follow,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getMyFollowers,
  getMyFollowings,
})(Follows);
