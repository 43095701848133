import React, { useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import styles from './auth.module.css'; // Ensure this path is correct
import icon from '../../img/CuteIcon.png';

const Register = ({ setAlert, register, isAuthenticated }) => {
  const location = useLocation();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();

  const [formData, setFormData] = useState({
    username: '',
    email: query.get('email') || '',
    password: '',
    password2: '',
    emailToken: query.get('emailToken') || '',
    agreedToTerms: false,
  });

  const { username, email, password, password2, agreedToTerms, emailToken } = formData;
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const isEmailDisabled = query.get('email') ? true : false;

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    validateForm({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    register({ username, email, password, emailToken });
  };

  const validateForm = (currentFormData) => {
    const { email, password, password2, agreedToTerms, username } = currentFormData;
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.length > 0;
    const isPasswordMatch = password === password2 && password.length >= 6;
    setIsValid(username.length > 0 && isEmailValid && isPasswordMatch && agreedToTerms);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  if (!isEmailDisabled || query.get('step') !== 'register' || !emailToken) {
    return <Navigate to="/email-submission" replace />;
  }

  return (
    <section className="container">
      <section className={styles.authContainer}>
        <img className="profiles-icon-medium " src={icon} alt=""></img>
        <h1 className={styles.largeText}>Sign Up</h1>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.formGroup}>
            <input
              type="text"
              placeholder="Username"
              name="username"
              className={styles.inputField}
              value={username}
              onChange={onChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              className={styles.inputField}
              value={email}
              onChange={onChange}
              required
              disabled={isEmailDisabled} // Disable this input if isEmailDisabled is true
            />
            <div
              className={`${styles.errorMessage} ${
                !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.length > 0 ? styles.visible : ''
              }`}
            >
              Invalid email format
            </div>
          </div>

          <div className={styles.formGroup}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              name="password"
              className={styles.inputField}
              value={password}
              onChange={onChange}
              minLength="6"
              required
            />
            <div
              className={`${styles.errorMessage} ${
                password !== password2 && password2.length > 0 ? styles.visible : ''
              }`}
            >
              Passwords do not match
            </div>
            <i
              onClick={togglePasswordVisibility}
              className={`${styles.passwordIcon} ${
                showPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'
              }`}
            ></i>
          </div>
          <div className={styles.formGroup}>
            <input
              type="password"
              placeholder="Confirm Password"
              name="password2"
              className={styles.inputField}
              value={password2}
              onChange={onChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type="checkbox"
              id="terms"
              name="terms"
              checked={agreedToTerms}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, agreedToTerms: e.target.checked }));
                validateForm({ ...formData, agreedToTerms: e.target.checked });
              }}
            />
            <label htmlFor="terms" className={styles.labelTerms}>
              I agree to the{' '}
              <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </label>
          </div>
          <input
            type="submit"
            className={`${styles.loginButton} ${!isValid ? styles.disabledButton : ''}`}
            value="REGISTER"
            disabled={!isValid}
          />
        </form>
        <p className={styles.bottomText}>
          Already have an account? <Link to="/login">Sign In</Link>
        </p>
      </section>
    </section>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
