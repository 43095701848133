// chatReducer.js
import {
  ADD_CHAT,
  GET_CHATS,
  GET_CHAT,
  CHAT_ERROR,
  UPDATE_CHAT,
  DELETE_CHAT,
  GET_ACTIVATION_CODES,
} from '../actions/types';

const initialState = {
  chats: [],
  chat: null,
  isLoadingAllChats: true,
  isLoading: true,
  error: null,
};

function chatReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_CHAT:
      return {
        ...state,
        chats: [payload, ...state.chats],
        isLoading: false,
      };
    case GET_CHATS:
      return {
        ...state,
        chats: payload,
        isLoadingAllChats: false,
      };
    case GET_CHAT:
      return {
        ...state,
        chat: payload,
        isLoading: false,
      };
    case UPDATE_CHAT:
      return {
        ...state,
        chats: state.chats.map((chat) => (chat._id === payload._id ? payload : chat)),
        isLoading: false,
      };
    case DELETE_CHAT:
      return {
        ...state,
        chats: state.chats.filter((chat) => chat._id !== payload),
        isLoading: false,
      };
    case CHAT_ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
        isLoadingAllChats: false,
      };
    case GET_ACTIVATION_CODES:
      return {
        ...state,
        activationCodes: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default chatReducer;
