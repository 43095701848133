// src/components/layout/Footer.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import './Footer.css'; // Import the CSS file if you create one

const Footer = () => {
  const location = useLocation();
  if (
    location.pathname === '/login' ||
    location.pathname === '/register' ||
    location.pathname.includes('/reset-password') ||
    location.pathname === '/auth-handler' ||
    location.pathname === '/email-submission' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/reset-password-email-submission' ||
    location.pathname === '/reset-password-verify-email' ||
    location.pathname.includes('chatbot')
  ) {
    return null;
  }
  return (
    <footer className="footer">
      <div className="footer-container">
        <span className="copyright-info">© 2023 Katfun.com All rights reserved.</span>
        <a href="https://forms.gle/w8twLvw85zGcY2DA9" target="_blank" rel="noopener noreferrer">
          Take our survey, help us enhance our platform for all pet lovers!
        </a>
        <a href="contact" target="_blank" rel="noopener noreferrer">
          <span>Contact Us</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
