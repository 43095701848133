import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import timeSince from '../../utils/calculateDate';
import { connect } from 'react-redux';
import { addLike, removeLike } from '../../actions/post';
import spinner from '../layout/spinner.gif';

import './PostItem.css';

const PostItem = ({
  addLike,
  removeLike,
  auth,
  post: {
    _id,
    title,
    name,
    avatar,
    user,
    likes,
    likesCount,
    created,
    profile,
    cover,
    cat,
    isLiked,
    video,
  },
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const handleOnClickLikePost = async () => {
    if (isDebouncing) return;
    setIsDebouncing(true);
    if (isLiked) {
      await removeLike(_id);
    } else {
      await addLike(_id);
    }
    setTimeout(() => setIsDebouncing(false), 500);
  };

  return (
    <Fragment>
      <div className="bg-light post-item ">
        <Link to={`/posts/${_id}`}>
          {/* Show spinner while the image is loading */}
          {!loaded && (
            <img
              src={spinner} // Replace with your spinner's local or imported path
              alt="Loading..."
              className="spinner"
            />
          )}
          {/* Once the image is loaded, it will trigger the onLoad event */}
          {video ? (
            <>
              {cover ? (
                <div className="video-thumbnail">
                  <img
                    src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + cover}
                    alt=""
                    onLoad={() => setLoaded(true)}
                    style={{ display: loaded ? 'block' : 'none' }}
                  />
                  <div className="play-icon" style={{ display: loaded ? 'block' : 'none' }}></div>
                </div>
              ) : (
                <video
                  controls
                  playsinline
                  onLoadedMetadata={() => setLoaded(true)}
                  style={{ display: loaded ? 'block' : 'none' }}
                >
                  <source
                    src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + video}
                    type="video/mp4"
                  />
                </video>
              )}
            </>
          ) : (
            /* Render image if there's no video */
            <img
              src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + cover}
              alt=""
              onLoad={() => setLoaded(true)}
              style={{ display: loaded ? 'block' : 'none' }}
            />
          )}
        </Link>
        <div className="post-title">
          <h4 className="my">{title}</h4>
        </div>
        <div className="post-date-like-grid">
          <div className="profile-post-date ">
            <small className="form-text">{timeSince(created)}</small>
          </div>
          <div className="profile-post-like ">
            <div className="icon-box" onClick={handleOnClickLikePost}>
              {isLiked ? (
                <i className="fa-solid fa-heart" style={{ color: '#ed0202' }} />
              ) : (
                <i className="fa-regular fa-heart"></i>
              )}
            </div>{' '}
            <span>{likesCount > 0 && <span>{likesCount}</span>}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addLike, removeLike })(PostItem);
