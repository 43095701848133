import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import MobileNavbar from './components/layout/MobileNavbar';
import Landing from './components/layout/Landing';
import Footer from './components/layout/Footer';
import EmailSubmission from './components/auth/EmailVerify/EmailSubmission';
import VerifyEmail from './components/auth/EmailVerify/VerifyEmail';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import AuthHandler from './components/auth/AuthHandler';
import ResetPwdEmailSubmission from './components/auth/PasswordRest/ResetPwdEmailSubmission';
import ResetPwdVerifyEmail from './components/auth/PasswordRest/ResetPwdVerifyEmail';
import ResetPassword from './components/auth/PasswordRest/ResetPassword';
import TermsAndConditions from './components/auth/Terms/TermsAndConditions';
import Contact from './components/layout/Contact';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import ProfileForm from './components/profile-forms/ProfileForm';
import AddExperience from './components/profile-forms/AddExperience';
import AddEducation from './components/profile-forms/AddEducation';
import CatForm from './components/profile-forms/CatForm';
import CatProfiles from './components/profiles/CatProfiles';
import Profile from './components/profile/Profile';
import Posts from './components/posts/Posts';
import PostsGuest from './components/posts/PostsGuest';
import PostsFollowing from './components/posts/PostsFollowing';
import Post from './components/post/Post';
import Notifications from './components/notifications/Notifications';
import Follow from './components/profile/follows/Follows';
import Chatbot from './components/chatbots/Chatbot';
import ChatbotList from './components/chatbots/ChatbotList';
import BotWelcome from './components/chatbots/BotWelcome';

// Admin page
import VetbotManagement from './components/dashboard/VetbotManagement';
import VetbotRoute from './components/routing/VetbotRoute';
import AdminRoute from './components/routing/AdminRoute';
import GenerateJsonl from './components/admin/GenerateJsonl';
import GenerateJsonlChat from './components/admin/GenerateJsonlChat';

import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import { LOGOUT } from './actions/types';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

//OneSignal
// import runOneSignal from './utils/onesignal';
//general
import ScrollToTop from './components/general/ScrollToTop/ScrollToTop';

import './App.css';

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  // useEffect(() => {
  //   runOneSignal();
  // });

  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<PostsGuest />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/email-submission" element={<EmailSubmission />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="auth-handler" element={<AuthHandler />} />
          <Route path="reset-password-email-submission" element={<ResetPwdEmailSubmission />} />
          <Route path="reset-password-verify-email" element={<ResetPwdVerifyEmail />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="contact" element={<Contact />} />
          <Route path="profiles" element={<CatProfiles />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="posts/guest" element={<PostsGuest />} />
          <Route path="vetgpt" element={<BotWelcome />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="dashboard" element={<PrivateRoute component={Dashboard} />} />
          <Route path="create-profile" element={<PrivateRoute component={ProfileForm} />} />
          <Route path="edit-profile" element={<PrivateRoute component={ProfileForm} />} />
          <Route path="add-experience" element={<PrivateRoute component={AddExperience} />} />
          <Route path="add-education" element={<PrivateRoute component={AddEducation} />} />
          <Route path="add-cat" element={<PrivateRoute component={CatForm} />} />
          <Route path="update-cat" element={<PrivateRoute component={CatForm} />} />
          <Route path="posts" element={<PrivateRoute component={Posts} />} />
          <Route path="posts-following" element={<PrivateRoute component={PostsFollowing} />} />
          <Route path="posts/:id" element={<Post />} />
          <Route path="inbox" element={<PrivateRoute component={Notifications} />} />
          <Route path="follow" element={<PrivateRoute component={Follow} />} />
          <Route path="chatbot/:id" element={<VetbotRoute component={Chatbot} />} />
          <Route path="chatbots" element={<VetbotRoute component={ChatbotList} />} />
          <Route
            path="admin/vetbot-management"
            element={<AdminRoute component={VetbotManagement} />}
          />
          <Route path="admin/generate-jsonl" element={<AdminRoute component={GenerateJsonl} />} />
          <Route
            path="admin/generate-jsonl-chat/:id"
            element={<AdminRoute component={GenerateJsonlChat} />}
          />
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <MobileNavbar />
        <Footer />
      </Router>
    </Provider>
  );
};

export default App;
