import {
  GET_UNREAD_INBOX,
  GET_READ_INBOX,
  INBOX_ERROR,
  READ_ONE_INBOX,
  READ_ALL_INBOX,
  GET_INBOX_BY_TYPE,
} from '../actions/types';

const initialState = {
  notifications: [],
  totalLikeNotifications: 0,
  totalUnreadLikeNotifications: 0,
  totalCommentNotifications: 0,
  totalUnreadCommentNotifications: 0,
  totalFollowNotifications: 0,
  totalUnreadFollowNotifications: 0,
  unreads: [],
  reads: [],
  loading: true,
  error: {},
};

function inboxReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_UNREAD_INBOX:
      return {
        ...state,
        unreads: payload.notifications,
        totalUnreads: payload.totalNotifications,
        totalNotifications: payload.totalNotifications,
        loading: false,
      };
    case GET_READ_INBOX:
      return {
        ...state,
        reads: payload.notifications,
        totalReads: payload.totalNotifications,
        totalNotifications: payload.totalNotifications,
        loading: false,
      };
    case GET_INBOX_BY_TYPE: {
      const { type, data } = payload;
      switch (type) {
        case 'like':
          return {
            ...state,
            totalLikeNotifications: data.totalNotifications,
            notifications: data.notifications,
            totalNotifications: data.totalNotifications,
            totalUnreadLikeNotifications: data.totalUnreadNotifications,
            loading: false,
          };
        case 'comment':
          return {
            ...state,
            totalCommentNotifications: data.totalNotifications,
            notifications: data.notifications,
            totalNotifications: data.totalNotifications,
            totalUnreadCommentNotifications: data.totalUnreadNotifications,
            loading: false,
          };
        case 'follow':
          return {
            ...state,
            totalFollowNotifications: data.totalNotifications,
            notifications: data.notifications,
            totalNotifications: data.totalNotifications,
            totalUnreadFollowNotifications: data.totalUnreadNotifications,
            loading: false,
          };
        default:
          return state;
      }
    }
    case READ_ONE_INBOX: {
      const updatedUnreads = state.unreads.filter(
        (notification) => notification._id !== payload._id,
      );
      const updatedReads = [payload, ...state.reads];
      return {
        ...state,
        unreads: updatedUnreads,
        reads: updatedReads,
        totalUnreads: state.totalUnreads - 1,
      };
    }
    case READ_ALL_INBOX: {
      const type = payload;
      switch (type) {
        case 'like':
          return {
            ...state,
            totalUnreadLikeNotifications: 0,
          };
        case 'comment':
          return {
            ...state,
            totalUnreadCommentNotifications: 0,
          };
        case 'follow':
          return {
            ...state,
            totalUnreadFollowNotifications: 0,
          };
        default:
          return state;
      }
    }
    case INBOX_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        unreads: [],
      };
    default:
      return state;
  }
}

export default inboxReducer;
