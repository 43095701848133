import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import timeSince from '../../utils/calculateDate';
import { HeartOutlined, HeartFilled, DeleteOutlined } from '@ant-design/icons';
import SubCommentForm from './SubCommentForm';
import {
  deleteComment,
  addCommentLike,
  removeCommentLike,
  getSubcommentsByCommentId,
} from '../../actions/post';
import Spinner from '../layout/Spinner';
import './PostItem.css';
import './Comment.css';
import styles from './Comment.module.css';
import subStyles from './SubComment.module.css';

const CommentItem = ({
  id,
  postId,
  comment: {
    _id,
    text,
    name,
    avatar,
    user,
    created,
    likes,
    likesCount,
    subcomments,
    subcommentsNum,
    isLiked,
  },
  comment,
  auth,
  auth: { isAuthenticated, loading },
  deleteComment,
  addCommentLike,
  removeCommentLike,
  getSubcommentsByCommentId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubcommentsVisible, setIsSubcommentsVisible] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const commentId = query.get('comment');
    const rootId =
      query.get('rootComment') === 'null' || query.get('rootComment') === 'undefined'
        ? null
        : query.get('rootComment');
    if (commentId && !rootId && commentId === _id) {
      const commentElement = document.getElementById(`comment-${commentId}`);
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }
    if (commentId && rootId && rootId === _id) {
      setTimeout(async () => {
        await getSubcommentsByCommentId(_id);
        setIsSubcommentsVisible(true);
        const commentElement = document.getElementById(`comment-${commentId}`);
        if (commentElement) {
          commentElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
      return;
    }
  }, [location, getSubcommentsByCommentId, _id]);
  const handleOnClickViewReplies = async () => {
    if (!isSubcommentsVisible) {
      await getSubcommentsByCommentId(_id);
    }
    setIsSubcommentsVisible(!isSubcommentsVisible);
  };
  const handleOnClickLikeComment = async (liked, commentId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    let rootId = '';
    if (commentId !== _id) {
      rootId = _id;
    }
    if (liked) {
      await removeCommentLike(commentId, rootId);
    } else {
      await addCommentLike(commentId, rootId);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete the comment?');
      if (confirmDelete) {
        let rootId = '';
        if (_id !== commentId) {
          rootId = _id;
        }
        const deleted = await deleteComment(postId, commentId, rootId);
        if (deleted) {
        }
      } else {
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return comment === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className={styles.commentContainer} id={`comment-${_id}`}>
        <div className={styles.header}>
          <Link to={`/profile/${user}`} className={styles.avatar}>
            <img
              src={`${process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT}${avatar}`}
              alt="Avatar"
              className={styles.avatar}
            />
          </Link>

          <div className={styles.userName}>{name}</div>
          <div className={styles.date}>{timeSince(created)}</div>
          {auth?.user?._id === user && (
            <button onClick={() => handleDeleteComment(_id)} className={styles.deleteButton}>
              <DeleteOutlined />
            </button>
          )}
        </div>
        <div className={styles.commentText}>{text}</div>
        <div className={styles.actions}>
          <div className={styles.actionButtonList}>
            <button
              className={styles.likeButton}
              onClick={() => handleOnClickLikeComment(isLiked, _id)}
            >
              {isLiked ? <HeartFilled style={{ color: 'red' }} /> : <HeartOutlined />}{' '}
              {likesCount || 0}
            </button>
            <SubCommentForm
              postId={postId}
              rootId={_id}
              callBack={() => {
                setIsSubcommentsVisible(true);
              }}
            />
          </div>
          {subcommentsNum > 0 && (
            <button className={styles.viewReplies} onClick={handleOnClickViewReplies}>
              {isSubcommentsVisible ? 'Hide Replies' : `View ${subcommentsNum} Replies`}
            </button>
          )}
        </div>
        <div
          className={`${styles.collapseTransition} ${
            isSubcommentsVisible ? styles.expand : styles.collapse
          }`}
        >
          {subcomments.map((sub) => (
            <div key={sub._id} id={`comment-${sub._id}`} className={subStyles.subcommentContainer}>
              <div className={subStyles.subcommentHeader}>
                <Link to={`/profile/${sub.user}`}>
                  <img
                    src={`${process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT}${sub.avatar}`}
                    alt="Avatar"
                    className={subStyles.subcommentAvatar}
                  />
                </Link>
                {/* <img
                  src={`${process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT}${sub.avatar}`}
                  alt="Avatar"
                  className={subStyles.subcommentAvatar}
                /> */}
                <div className={subStyles.subcommentName}>
                  {sub.name}{' '}
                  {sub.replyTo && (
                    <>
                      <span className={subStyles.replyArrow}>
                        <i className="fa-solid fa-play"></i>
                      </span>{' '}
                      {sub.replyToName}
                    </>
                  )}
                </div>
                <div className={subStyles.subcommentDate}>{timeSince(sub.created)}</div>
                {auth?.user?._id === sub.user && (
                  <button
                    onClick={() => handleDeleteComment(sub._id, _id)}
                    className={subStyles.deleteButton}
                  >
                    <DeleteOutlined />
                  </button>
                )}
              </div>
              <div className={subStyles.subcommentText}>{sub.text}</div>
              <div className={subStyles.subActions}>
                <div className={styles.actionButtonList}>
                  <button
                    className={subStyles.likeButton}
                    onClick={() => handleOnClickLikeComment(sub.isLiked, sub._id)}
                  >
                    {sub.isLiked ? <HeartFilled style={{ color: 'red' }} /> : <HeartOutlined />}{' '}
                    {sub.likesCount || 0}
                  </button>
                  <SubCommentForm postId={postId} rootId={_id} replyComment={sub} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

CommentItem.propTypes = {
  id: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
  addCommentLike: PropTypes.func.isRequired,
  removeCommentLike: PropTypes.func.isRequired,
  getSubcommentsByCommentId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteComment,
  addCommentLike,
  removeCommentLike,
  getSubcommentsByCommentId,
})(CommentItem);
