import {
  ADD_CHAT,
  GET_CHATS,
  GET_CHAT,
  CHAT_ERROR,
  UPDATE_CHAT,
  DELETE_CHAT,
  ACTIVATE_CHAT,
  GET_ACTIVATION_CODES,
} from './types';
import { setAlert } from './alert';
import api from '../utils/api';
//fetch stream messages
export const fetchStreamMessages = async (chatId, message = '') => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats/messages/${chatId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
        credentials: 'include',
      },
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    console.error('Failed to send message:', error);
    setAlert('Failed to send message', 'danger');
  }
};

const setAxiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
};

export const addChat = (chatData) => async (dispatch) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats`, {
      method: 'POST',
      body: JSON.stringify(chatData),
      ...setAxiosConfig,
    });
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await res.json();
    dispatch({
      type: ADD_CHAT,
      payload: data,
    });
    dispatch(setAlert('Chat Created', 'success'));
    return { data, success: true };
  } catch (error) {
    dispatch({
      type: CHAT_ERROR,
      payload: error.message,
    });
    dispatch(setAlert('Failed to create chat', 'danger'));
    return { success: false };
  }
};

export const getChats = () => async (dispatch) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats`,
      setAxiosConfig,
    );
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await res.json();
    dispatch({
      type: GET_CHATS,
      payload: data,
    });
    // dispatch(setAlert('Chats Loaded', 'success'));
  } catch (error) {
    dispatch({
      type: CHAT_ERROR,
      payload: error.message,
    });
    dispatch(setAlert('Failed to load chats', 'danger'));
  }
};

export const getChat = (chatId) => async (dispatch) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats/${chatId}`,
      setAxiosConfig,
    );
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await res.json();
    dispatch({
      type: GET_CHAT,
      payload: data,
    });
    dispatch(setAlert('Chat Loaded', 'success'));
  } catch (error) {
    dispatch({
      type: CHAT_ERROR,
      payload: error.message,
    });
    dispatch(setAlert('Failed to load chat', 'danger'));
  }
};
export const getChatdata = async (chatId) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats/${chatId}`,
      setAxiosConfig,
    );
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await res.json();
    return data;
  } catch (error) {
    console.error('Failed to get chat data:', error);
    setAlert('Failed to get chat data', 'danger');
    return null;
  }
};

export const deleteChat = (chatId) => async (dispatch) => {
  try {
    await fetch(`${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats/${chatId}`, {
      method: 'DELETE',
      ...setAxiosConfig,
    });
    dispatch({
      type: DELETE_CHAT,
      payload: chatId,
    });
    dispatch(setAlert('Chat Deleted', 'success'));
  } catch (error) {
    dispatch({
      type: CHAT_ERROR,
      payload: error.message,
    });
    dispatch(setAlert('Failed to delete chat', 'danger'));
  }
};

export const updateChat = (chatId, title) => async (dispatch) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats/${chatId}`,
      {
        method: 'POST',
        body: JSON.stringify({ title }),
        ...setAxiosConfig,
      },
    );
    if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await res.json();
    dispatch({
      type: UPDATE_CHAT,
      payload: data,
    });
    dispatch(setAlert('Chat Updated', 'success'));
  } catch (error) {
    dispatch({
      type: CHAT_ERROR,
      payload: error.message,
    });
    dispatch(setAlert('Failed to update chat', 'danger'));
  }
};

export const requestAccess = (isAuthenticated) => async (dispatch) => {
  try {
    if (!isAuthenticated) {
      dispatch(setAlert('Please login to request access', 'danger'));
      return null;
    }
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats/request/access`,
      {
        method: 'POST',
        ...setAxiosConfig,
      },
    );
    if (!res.ok) {
      setAlert('Failed to request access', 'danger');
      throw new Error(res.statusText);
    }
    const data = await res.json();
    dispatch(setAlert(data?.msg, 'success'));
    return data;
  } catch (error) {
    console.error('Failed to request access:', error);
    dispatch(setAlert('Failed to request access', 'danger'));
    return null;
  }
};

export const approveAccess = (userId, email) => async (dispatch) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_APIENDPOINT}/vetChatBots/chats/activation/grant/access`,
      {
        method: 'POST',
        body: JSON.stringify({ userId, email }),
        ...setAxiosConfig,
      },
    );
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    const data = await res.json();
    dispatch(setAlert(data?.msg, 'success'));
    return data;
  } catch (error) {
    console.error('Failed to approve access:', error);
    dispatch(setAlert('Failed to approve access', 'danger'));
    return null;
  }
};

export const activateCode = (code) => async (dispatch) => {
  try {
    if (!code) {
      dispatch(setAlert('Invalid code', 'danger'));
      return { success: false };
    }
    await api.post(`/vetChatBots/activate/${code}`);

    dispatch({
      type: ACTIVATE_CHAT,
    });
    dispatch(setAlert('Chat Activated', 'success'));
    return true;
  } catch (error) {
    console.error('Failed to activate code:', error);
    dispatch(setAlert('Failed to activate code', 'danger'));
    return null;
  }
};

export const getActivationCodes = (pageNum, pageSize, searchEmail) => async (dispatch) => {
  try {
    const res = await api.get(
      `/vetChatBots/chats/activate/codes?pageNum=${pageNum}&pageSize=${pageSize}&searchEmail=${searchEmail}`,
    );
    dispatch({
      type: GET_ACTIVATION_CODES,
      payload: res.data,
    });
    return res.data;
  } catch (error) {
    console.error('Failed to get activation codes:', error);
    dispatch(setAlert('error', 'danger'));
    return null;
  }
};
