import { useState, useEffect, useRef } from 'react';

const useSlideInAnimation = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAlreadyInView, setIsAlreadyInView] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const initialScrollY = useRef(window.scrollY);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const viewportHeight = window.innerHeight;
          const isAbove = entry.boundingClientRect.top < initialScrollY.current + viewportHeight;
          const isInitialLoad = window.scrollY < 10;
          if (entry.isIntersecting && !hasAnimated) {
            if (window.scrollY > initialScrollY.current + viewportHeight) {
              setIsVisible(true);
              setHasAnimated(true);
            } else if (isInitialLoad) {
              setIsAlreadyInView(true);
            } else if (isAbove) {
              setIsAlreadyInView(true);
            }
          } else if (isAbove) {
            // 如果元素不在视图内但在上方，也设置为最终状态
            setIsAlreadyInView(true);
          }
        });
      },
      { threshold: 0.15 },
    );

    const handleAnimationEnd = () => {
      setIsVisible(false);
    };

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef);
      currentRef.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
        currentRef.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [ref, hasAnimated]);

  return { ref, isVisible, isAlreadyInView };
};

export default useSlideInAnimation;
