import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchStreamMessages, getChatdata } from '../../actions/chatbot';
import styles from './chatbot.module.css';
import doctorIcon from './img/Doctor.webp';

const Chatbot = ({ auth: { user } }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [botMessage, setBotMessage] = useState('');
  const [isBotThinking, setIsBotThinking] = useState(false);
  const [chatId, setChatId] = useState(''); // Assuming the chat ID is in the URL like /chatbot/:chatId
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const chatId = location.pathname.split('/')[2];
    setChatId(chatId);
    setIsLoading(true);
    getChatdata(chatId).then((res) => {
      if (!res || !res.messages) {
        setIsLoading(false);
        return;
      }
      setMessages(res.messages);
      setIsLoading(false);
    });
  }, [location]);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const sendMessage = async () => {
    if (!message) return;

    setIsLoading(true);
    setMessage('');
    let newMessage = { role: 'user', content: message };
    setMessages((messages) => [...messages, newMessage]);
    setIsBotThinking(true);
    try {
      const response = await fetchStreamMessages(chatId, message);
      const reader = response.body.getReader();
      setIsBotThinking(false);
      let completeBotMessage = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decoder = new TextDecoder();
        const txt = decoder.decode(value);
        completeBotMessage += txt; // 累积完整的机器人回复
        setBotMessage((prev) => prev + txt); // 实时更新流式打印效果
        await new Promise((resolve) => setTimeout(resolve, 50)); // 模拟延迟
      }

      let botMessageObj = { role: 'bot', content: completeBotMessage };
      setMessages((messages) => [...messages, botMessageObj]);
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsLoading(false);
      setBotMessage(''); // 清除临时显示的botMessage
    }
  };

  return (
    <div className={'container'}>
      <div className={styles.messages}>
        {messages &&
          messages?.length > 0 &&
          messages.map((msg, index) => (
            <div
              key={index}
              className={
                msg.role === 'user'
                  ? `${styles.userMessage} ${styles.messageWithAvatar}`
                  : `${styles.botMessage} ${styles.messageWithAvatar}`
              }
            >
              <div
                className={msg.role === 'user' ? styles.userAvatar : styles.botAvatar}
                style={{
                  backgroundImage: `url(${
                    msg.role === 'user'
                      ? process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + user.avatar
                      : doctorIcon
                  })`,
                  width: '30px',
                  height: '30px',
                  backgroundSize: 'cover', // Ensure the image covers the area
                }}
              />
              <div className={styles.messageText}>
                {msg.content}
                {msg.role === 'user' && (
                  <span style={{ marginLeft: '5px', fontSize: '0.8em', color: '#888' }}>Me</span>
                )}
              </div>
            </div>
          ))}
        <div className={`${styles.botMessage}`}>
          {isBotThinking && (
            <div className={styles.loadingDots}>
              <span className={styles.loadingDot}>.</span>
              <span className={styles.loadingDot}>.</span>
              <span className={styles.loadingDot}>.</span>
            </div>
          )}
        </div>
        <div className={styles.botMessage}>{botMessage}</div>
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.messageInputArea}>
        <button
          className={styles.backButton}
          onClick={() => {
            /* 导航逻辑 */
            navigate('/chatbots');
          }}
        >
          {/* 这里假设您使用FontAwesome，或者您可以使用文本 */}
          <i className="fa fa-arrow-left"></i>
        </button>
        <textarea
          className={styles.messageInput}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
          disabled={isLoading}
        />
        <button
          className={styles.sendButton}
          onClick={sendMessage}
          disabled={!message.trim() || isLoading}
        >
          {/* 如果你使用图标库，例如FontAwesome，可以如下插入图标 */}
          <i className={`fa fa-paper-plane ${styles.sendButtonIcon}`} />
          {/* 否则可以使用文本 */}
        </button>
      </div>
    </div>
  );
};

Chatbot.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Chatbot);
