import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updateJsonlMessages, createJsonlMessages } from '../../actions/jsonl';
import styles from './GenerateJsonlChat.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

const GenerateJsonl = ({
  auth,
  jsonl: { messagesList },
  updateJsonlMessages,
  createJsonlMessages,
}) => {
  const navigate = useNavigate();
  const [editMessageId, setEditMessageId] = useState(null);
  const [editFormData, setEditFormData] = useState({ role: '', content: '' });

  const location = useLocation();
  const [chatId, setChatId] = useState('');
  const [messages, setMessages] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    const chatId = location.pathname.split('/')[3];
    setChatId(chatId);
    if (chatId) {
      setIsEditing(true);
      // Fetch the chat data by chatId
      let messagesObject = messagesList.find((message) => message._id === chatId);
      if (!messagesObject) {
        setMessages([]);
        setIsEditing(false);
        return;
      }
      let messagesWithId = messagesObject.messages.map((message, index) => {
        return { _id: uuidv4(), ...message };
      });
      setMessages(messagesWithId);
    }
  }, [location, messagesList]);
  const handleEditFormChange = (event) => {
    const { name, value } = event.target;
    setEditFormData({ ...editFormData, [name]: value });
  };
  const handleEditMessage = (message) => {
    setEditMessageId(message._id);
    setEditFormData({ role: message.role, content: message.content });
  };

  const handleSaveEdit = () => {
    const updatedMessages = messages.map((message) =>
      message._id === editMessageId ? { ...message, ...editFormData } : message,
    );
    setMessages(updatedMessages);
    setEditMessageId(null);
  };

  const handleAddMessage = () => {
    const newMessage = {
      _id: uuidv4(), // Generates a unique UUID for each new message
      role: 'user',
      content: '',
    };
    setMessages([...messages, newMessage]);
    handleEditMessage(newMessage);
  };
  const handleDelete = (chatId) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      const updatedMessages = messages.filter((message) => message._id !== chatId);
      setMessages(updatedMessages);
      if (editMessageId === chatId) {
        // Reset edit state if currently editing message is deleted
        setEditMessageId(null);
        setEditFormData({ role: '', content: '' });
      }
    }
  };
  const handleSaveMessages = async () => {
    const messagesWitoutId = messages.map(({ _id, ...message }) => message);
    if (isEditing) {
      updateJsonlMessages(chatId, messagesWitoutId);
    } else {
      createJsonlMessages(messagesWitoutId);
    }
    navigate('/admin/generate-jsonl');
  };

  return (
    <div className="container">
      <button onClick={handleAddMessage} className={styles.addButton}>
        <FontAwesomeIcon icon={faPlus} /> Add New Message
      </button>
      <ul className={styles.chatList}>
        {messages.map((message, index) => (
          <li key={index} className={styles.chatItem}>
            {editMessageId === message._id ? (
              <div className={styles.editingSection}>
                <select
                  name="role"
                  value={editFormData.role}
                  onChange={handleEditFormChange}
                  className={styles.roleSelect} // 可以添加CSS类来进行样式定义
                >
                  <option value="system">System</option>
                  <option value="user">User</option>
                  <option value="assistant">Assistant</option>
                </select>
                <textarea
                  type="text"
                  name="content"
                  value={editFormData.content}
                  className={styles.contentInput}
                  onChange={handleEditFormChange}
                />
                <button onClick={handleSaveEdit} className={styles.saveButton}>
                  Save
                </button>
              </div>
            ) : (
              <div>
                <span className={styles.chatRole}>{message.role}: </span>
                <span className={styles.chatContent}>{message.content}</span>
                <button onClick={() => handleEditMessage(message)} className={styles.editButton}>
                  Edit
                </button>
                <button onClick={() => handleDelete(message._id)} className={styles.trashButton}>
                  Delete
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
      <button onClick={handleAddMessage} className={styles.addButton}>
        <FontAwesomeIcon icon={faPlus} /> Add New Message
      </button>
      <button onClick={handleSaveMessages} className={styles.addButton}>
        Save Messages
      </button>
    </div>
  );
};

GenerateJsonl.propTypes = {
  auth: PropTypes.object.isRequired,
  updateJsonlMessages: PropTypes.func.isRequired,
  createJsonlMessages: PropTypes.func.isRequired,
  jsonl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  jsonl: state.jsonl,
});

export default connect(mapStateToProps, { updateJsonlMessages, createJsonlMessages })(
  GenerateJsonl,
);
