import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import timeSince from '../../utils/calculateDate';
import { connect } from 'react-redux';
import { addLike, removeLike, deletePost } from '../../actions/post';
import spinner from '../layout/spinner.gif';
import './PostItem.css';
import useSlideInAnimation from '../animation/scrollDownSlideIn/useSlideInAnimation';
// import useFadeInTopToBottom from '../animation/fadeIn/useFadeInTopToBottom';
import '../animation/scrollDownSlideIn/scrollDownSlideIn.css';

const PostItem = ({
  addLike,
  removeLike,
  deletePost,
  auth,
  post: {
    _id,
    title,
    name,
    avatar,
    user,
    likes,
    likesCount,
    created,
    profile,
    cover,
    cat,
    isLiked,
    video,
    text,
  },
}) => {
  const [loaded, setLoaded] = useState(false);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const navigate = useNavigate();
  const { ref, isVisible, isAlreadyInView } = useSlideInAnimation();
  const handleOnClickLikePost = async () => {
    if (isDebouncing) return;
    if (auth && auth.isAuthenticated === false) {
      navigate('/landing');
    }
    setIsDebouncing(true);
    if (isLiked) {
      await removeLike(_id);
    } else {
      await addLike(_id);
    }
    setTimeout(() => setIsDebouncing(false), 500);
  };

  return (
    <Fragment>
      <div
        ref={ref}
        className={`post-item bg-light
        ${isAlreadyInView ? '' : 'initial-position'}
        ${isVisible ? 'slide-in-up' : ''}`}
      >
        <Link to={`/posts/${_id}`}>
          {/* Show spinner while the image is loading */}
          {!loaded && (
            <img
              src={spinner} // Replace with your spinner's local or imported path
              alt="Loading..."
              className="spinner"
            />
          )}
          {/* Once the image is loaded, it will trigger the onLoad event */}
          {video ? (
            <>
              {cover ? (
                <div className="video-thumbnail">
                  <img
                    src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + cover}
                    alt={`${name}: ${title} - ${text}`}
                    onLoad={() => setLoaded(true)}
                    style={{ display: loaded ? 'block' : 'none' }}
                  />
                  <div className="play-icon" style={{ display: loaded ? 'block' : 'none' }}></div>
                </div>
              ) : (
                <video
                  controls
                  playsinline
                  onLoadedMetadata={() => setLoaded(true)}
                  style={{ display: loaded ? 'block' : 'none' }}
                >
                  <source
                    src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + video}
                    type="video/mp4"
                  />
                </video>
              )}
            </>
          ) : (
            /* Render image if there's no video */
            <>
              {cover && (
                <img
                  src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + cover}
                  alt={`${name}: ${title} - ${text}`}
                  onLoad={() => setLoaded(true)}
                  style={{ display: loaded ? 'block' : 'none' }}
                />
              )}
            </>
          )}
        </Link>
        <div className="post-title">
          <h4 className="my">{title}</h4>
        </div>
        <div className="post-grid">
          <div className="post-avatar ">
            {cat ? (
              <Link to={`/profile/${user}`}>
                {cat.avatar ? (
                  <img
                    src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + cat.avatar}
                    alt={cat.name}
                  />
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT +
                      'public/user/avatar/cat-default-avatar.png'
                    }
                    alt={cat.name}
                  />
                )}
              </Link>
            ) : (
              <Link to={`/profile/${user}`}>
                {avatar ? (
                  <img src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + avatar} alt={name} />
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT +
                      'public/user/avatar/default-avatar.jpeg'
                    }
                    alt={name}
                  />
                )}
              </Link>
            )}
          </div>
          <div className="post-name">
            <Link to={`/profile/${user}`} style={{ color: 'var(--primary-dark-color)' }}>
              <div className="mobile-layout">
                <h5>{cat && cat.name ? cat.name : name}</h5>
              </div>
              <div className="computer-layout">
                <h4>{cat && cat.name ? cat.name : name}</h4>
              </div>
            </Link>
          </div>
          <div className="post-date">
            <small className="form-text">{timeSince(created)}</small>
          </div>
          <div className="post-like ">
            <div className="icon-box" onClick={handleOnClickLikePost}>
              {isLiked ? (
                <i className="fa-solid fa-heart" style={{ color: '#ed0202' }} />
              ) : (
                <i className="fa-regular fa-heart"></i>
              )}
            </div>{' '}
            <span>{likesCount > 0 && <span>{likesCount}</span>}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  deletePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addLike, removeLike, deletePost })(PostItem);
