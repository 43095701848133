import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'; // you might need to install moment for timestamp formatting
import { readOneNotification } from '../../actions/notification';
import { getOneComments } from '../../actions/post';
import FollowButton from '../general/follow-buttun/FollowButton';
import './NotificationItem.css'; // make sure you have the correct path to your CSS file

const NotificationItem = ({
  notification: {
    _id,
    type,
    fromUser,
    fromProfile,
    fromName,
    post,
    comment,
    rootComment,
    message,
    avatar,
    postCover,
    isRead,
    createdAt,
  },
  readOneNotification,
  getOneComments,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // When the component mounts, set the fade in effect
    setFadeIn(true);
  }, []);
  const handleOnClickNameAvatar = () => {
    if (fromUser) navigate(`/profile/${fromUser}`);
  };
  // This is the function that will be called when a comment notification is clicked
  const handleOnClickCommentCover = (postId, commentId) => {
    // console.log('commentId', commentId);
    // Navigate to the post page
    // navigate(`/posts/${postId}`);
    readOneNotification(_id);
    // Wait for the navigation and page load to complete
    // setTimeout(async () => {
    //   // Now that we're on the post page, find the comment element
    //   const commentElement = document.getElementById(`comment-${commentId}`);
    //   // If the comment element exists, scroll to it
    //   commentElement?.scrollIntoView({ behavior: 'smooth' });
    // }, 1000); // You might need to adjust the timeout based on your app's behavior
  };

  const handleOnClickLikesCover = (postId) => {
    // Navigate to the post page
    // navigate(`/posts/${postId}`);
    readOneNotification(_id);
  };

  const handleIsFollowingChange = (following) => {
    setTimeout(() => {
      if (isRead) return;
      readOneNotification(_id);
    }, 1000);
  };

  return (
    <div
      className={`notification-item ${isRead ? 'read' : 'unread'} ${
        fadeIn ? 'fadeInTopToBottom' : ''
      }`}
    >
      <div className="notification-avatar" onClick={handleOnClickNameAvatar}>
        <Link to={`/profile/${fromUser}`}>
          <img src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + avatar} alt={fromName} />
        </Link>
        {/* <img
          src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + fromUser.avatar}
          alt={fromUser.name}
        /> */}
      </div>
      <div className="notification-info">
        <div className="notification-text">
          <Link to={`/profile/${fromUser}`}>
            <strong
              className={`notification-name${isRead ? '-read' : ''}`}
              style={{ cursor: 'pointer' }}
              onClick={handleOnClickNameAvatar}
            >
              {fromName}
            </strong>
          </Link>
          {post && (
            <>
              {type === 'comment' ? (
                <Link
                  to={`/posts/${post}?comment=${comment}&rootComment=${rootComment}`}
                  style={{ cursor: 'pointer', color: `${isRead ? 'gray' : 'black'}` }}
                  onClick={() => {
                    handleOnClickCommentCover(post, comment);
                  }}
                >
                  {message}
                </Link>
              ) : (
                <Link
                  to={`/posts/${post}?comment=${comment}&rootComment=${rootComment}`}
                  style={{ cursor: 'pointer', color: `${isRead ? 'gray' : 'black'}` }}
                  onClick={() => {
                    handleOnClickLikesCover(post);
                  }}
                >
                  {message}
                </Link>
              )}
            </>
          )}
          {!post && <span>{message}</span>}
        </div>
      </div>

      <div className="notification-time">{moment(createdAt).fromNow()}</div>
      {post && (
        <Link
          to={`/posts/${post}`}
          style={{ cursor: 'pointer' }}
          className="notification-post-thumbnail"
          onClick={() => {
            handleOnClickLikesCover(post);
          }}
        >
          <img
            src={process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + postCover}
            alt="Post thumbnail"
          />{' '}
          {/* Replace `post.thumbnail` with your actual thumbnail property */}
        </Link>
      )}
      {type === 'follow' && (
        <>
          <div className="notification-follow-button">
            <FollowButton user={fromUser} onFollowingChange={handleIsFollowingChange} />
          </div>
        </>
      )}
    </div>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  readOneNotification: PropTypes.func.isRequired,
  getOneComments: PropTypes.func.isRequired,
};

export default connect(null, { readOneNotification, getOneComments })(NotificationItem);
