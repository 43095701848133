import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PostItem from './PostItem';
import { getPosts, loadMorePosts } from '../../actions/post';
import { FloatButton } from 'antd';
import { Input } from 'antd';
import Spinner from '../layout/Spinner';
import { useThrottle } from '../../utils/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CreatePostModal from './CreatePostModal';
import './Posts.css';

const { Search } = Input;

const Posts = ({
  getPosts,
  loadMorePosts,
  post: { posts, totalPosts, continuePosts, loading, loadedAll },
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  let searchQuery = query.get('search') || '';
  let pageQuery = query.get('page') || 1;
  let pageSizeQuery = query.get('pageSize') || 12;
  const [isPostsLoaded, setIsPostsLoaded] = React.useState(false);
  const spinnerRef = useRef(null);
  // const observer = useRef(null);
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleLoadmorePosts = useThrottle(async () => {
    if (isFetching) return;
    if (page * pageSizeQuery >= totalPosts) return;
    await loadMorePosts(page + 1, 12, searchQuery);
    setPage(page + 1);
    setIsFetching(false);
  }, 1000);

  useEffect(() => {
    const handleObserver = (entities) => {
      const [entry] = entities;
      // console.log(entry.isIntersecting);
      if (entry.isIntersecting) {
        handleLoadmorePosts();
      }
    };
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });
    const spinnerRefCurrent = spinnerRef.current;
    if (spinnerRef.current) {
      observer.observe(spinnerRef.current);
    }
    return () => {
      if (spinnerRefCurrent) {
        observer.unobserve(spinnerRefCurrent);
      }
    };
  }, [spinnerRef, handleLoadmorePosts]);

  useEffect(() => {
    const fetchPosts = async () => {
      await loadMorePosts(pageQuery, pageSizeQuery, searchQuery);
      setIsPostsLoaded(true);
    };
    fetchPosts();
  }, [loadMorePosts, pageQuery, pageSizeQuery, searchQuery]);

  const onSearch = async (value, _e, info) => {
    if (value === '') {
      navigate(`/posts`);
    }
    navigate(`/posts?search=${value}`);
  };

  return (
    <section className="container">
      <div className="posts-list-header-container">
        <div className="posts-list-header-search-input">
          <Search
            className="computer-layout"
            placeholder="search posts"
            defaultValue={searchQuery}
            allowClear
            size="large"
            onSearch={onSearch}
          />
        </div>
      </div>

      <Search
        className="mobile-layout"
        style={{ marginBottom: '0.5rem' }}
        placeholder="search posts"
        allowClear
        size="large"
        onSearch={onSearch}
      />
      {isPostsLoaded && continuePosts && continuePosts.length > 0 && (
        <>
          <div className="computer-layout">
            <div className={`posts`}>
              <div className="items-container">
                {continuePosts
                  .filter((_, index) => index % 4 === 0)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {continuePosts
                  .filter((_, index) => index % 4 === 1)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {continuePosts
                  .filter((_, index) => index % 4 === 2)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {continuePosts
                  .filter((_, index) => index % 4 === 3)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
            </div>
          </div>

          <div className="mobile-layout">
            <div className="posts">
              <div className="items-container">
                {continuePosts
                  .filter((_, index) => index % 2 !== 0)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
              <div className="items-container">
                {continuePosts
                  .filter((_, index) => index % 2 === 0)
                  .map((post) => (
                    <PostItem key={post._id} post={post} />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      {isPostsLoaded && continuePosts && continuePosts.length === 0 && (
        <div className="posts">
          <h4>No posts found...</h4>
        </div>
      )}

      <div
        ref={spinnerRef}
        style={{
          display: `${isPostsLoaded && continuePosts.length > 0 && !loadedAll ? 'block' : 'none'}`,
        }}
      >
        <Spinner />
      </div>
      <FloatButton.Group shape="circle" style={{ right: 48 }}>
        <FloatButton
          type="primary"
          className="float-button-add-post"
          icon={<FontAwesomeIcon icon={faPlus} />}
          styles={{ defaultBg: '#1890ff' }}
          badge={{
            dot: true,
          }}
          onClick={() => {
            setModalVisible(true);
          }}
        ></FloatButton>
        <FloatButton.BackTop
          className="float-button-add-post"
          style={{ zoom: 1.3 }}
          visibilityHeight={0}
        />
      </FloatButton.Group>
      <CreatePostModal visible={modalVisible} onClose={() => setModalVisible(false)} />
    </section>
  );
};

Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  loadMorePosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPosts, loadMorePosts })(Posts);
